import React, { Component, useEffect, useState } from 'react'
import { connect } from 'react-redux';
// import { doc, onSnapshot } from "firebase/firestore";
import { collection, query, where, onSnapshot, FieldValue,setDoc, doc, serverTimestamp, orderBy, getDoc } from "firebase/firestore";
// import { collection, orderBy, query, where, getDocs, doc, deleteDoc,setDoc, getDoc } from "firebase/firestore"; 
import { storage, db } from "../firebases";
// import { useCollectionData } from 'react-firebase-hooks/firestore';
import { v4 } from 'uuid';
import { Link } from 'react-router-dom';

const AllChart = (props)=> {
    const [userId, setUserId] = useState(null)
    const convertTimestamp =(timestamp)=> {
        if(timestamp){
        let date = timestamp.toDate();
        let mm = date.getMonth();
        let dd = date.getDate();
        let yyyy = date.getFullYear();
        let hours = date.getHours()
        let mins = date.getMinutes()
    
        date = mm + '/' + dd + '/' + yyyy +' '+ `${hours}:${mins}`;
        return date;
        }
        return 
    }
    
    
//     if(!userId || userId.trim() !== ''){
//         const q = query(collection(db, `chats/${userId}/messages`), orderBy("createdAt"));
//         const subscribe = onSnapshot(q, (querySnapshot) => {
//         const msg = [];
//         querySnapshot.forEach((doc) => {
//             msg.push(doc.data());
//         });
//         setMessages(msg)
//         });
    
// }

    // if(!userId || userId.trim() !== ''){
    //     // const q = query(collection(db, `chats/${userId}/messages`), orderBy("createdAt"));
    //     const unsubscribe = onSnapshot(collection(db, `chats/${userId}/messages`), () => {
    //         // Respond to data
    //         // ...
    //       });
    // }
    

    const chatQuery = query(collection(db, "chats"), orderBy("lastMessageDateTime",'desc'));
    const messageListSnapShot = onSnapshot(chatQuery, (querySnapshot) => {
    const msgList = [];
    querySnapshot.forEach((doc) => {
        msgList.push(doc.data());
    });
    setAllMessages(msgList)
    });
    const [messages, setMessages] = useState([])
    const [allMessages, setAllMessages] = useState([])
    const [messageValue, setMessageValue] = useState('')
    const [customerName, setCustomerName] = useState('')
    const [customerEmail, setCustomerEmail] = useState('')
    
    useEffect(() => {
        // getUserDetails()
      }, [messages, allMessages])

    const sendMessage = async () => {
        if(!userId || userId.trim() !== ''){
    const docId= v4()
    
    const messageRef = doc(db, `chats/${userId}/messages`, docId);
        await setDoc(messageRef,
            {
                message: messageValue,
                sentBy: 'admin',
                createdAt: serverTimestamp(),
                              }
            , { merge: true })
          .then((doc) => {
            console.log('message sent')
          })
          .catch((error) => {
            console.log(error);
            alert('Error while sending a message')
          });

    setMessageValue('');
        }
    }

      //  const { fixNavbar } = this.props;
        return (
            <>
                <div className="section-light py-3 chat_app">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="card bg-none b-none">
                                    <div className="card-header bline pt-1">
                                        <h3 className="card-title">{customerName} <small>{customerEmail}</small></h3>
                                        {/* <div className="card-options">
                                            <a href="/#" className="p-1 chat_list_btn"><i className="fa fa-align-right" /></a>
                                            <a href="/#" className="p-1"><i className="fa fa-plus" /></a>
                                            <a href="/#" className="p-1"><i className="fa fa-cog" /></a>
                                            <a href="/#" className="p-1"><i className="fa fa-refresh" /></a>
                                        </div> */}
                                    </div>
                                    <div className="card-body">
											<div className="table-responsive">
												<table className="table table-striped table-hover table-vcenter text-nowrap mb-0">
													<thead>
														<tr>
														<th className="w60">#</th>
															<th style={{color: '#Dac27c'}} className="w60">Customer Name</th>
															<th style={{color: '#Dac27c'}}>Customer Email</th>
															<th style={{color: '#Dac27c'}}>Customer Id</th>
															<th style={{color: '#Dac27c'}} className='w100'>Action</th>
														</tr>
													</thead>
													<tbody>
														{
															allMessages.length > 0 ? (
																allMessages.map((msg, index)=>(
													<tr key={msg.id}>
														    <td>
																{index+1}
															</td>
															<td>
																<h6 style={{color: '#Dac27c'}}className="mb-0">{msg.customerName}</h6>
														
															</td>
															<td>{!msg.isRead?<b>{msg.customerEmail}</b>:msg.customerEmail}</td>
															<td>{!msg.isRead?<b>{msg.customerId}</b>:msg.customerId}</td>
															
															<td>
																{/* <button
																	type="button"
																	className="btn btn-icon"
																	title="Edit"
																>
																	<i className="fa fa-edit" />
																</button> */}
																<Link to={`/userChat/${msg.customerName}/${msg.customerEmail}/${msg.customerId}`}>
                                                                <i className="fa fa-comments-o" />
																</Link>
															</td>
														</tr>
																))
															) :
															<h6>No chats available right now</h6>
														}
													</tbody>
												</table>
											</div>
										</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="chat_list section-white" id="users">
                        <a href="/#" className="chat_list_btn float-right"><i className="fa fa-align-right" /></a>
                        <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="users-tab" data-toggle="tab" href="#users-list" role="tab" aria-controls="users-list" aria-selected="true">Users</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="groups-tab" data-toggle="tab" href="#groups" role="tab" aria-controls="groups" aria-selected="false">Groups</a>
                            </li>
                        </ul>
                        <div className="input-group mt-2 mb-2">
                            <input type="text" className="form-control search" placeholder="Search..." />
                        </div> */}
                        {/* <div className="tab-content">
                            <div className="tab-pane fade show active" id="users-list" role="tabpanel" aria-labelledby="users-tab">
                                <ul className="right_chat list-unstyled list">
                                    {allMessages.length>0? allMessages.map(msgg=>(
                                    <li className="online" onClick={()=>{
                                       
                                        setUserId(msgg.customerId)
                                        }}>
                                        
                                        <div className="media">
                                            <img className="media-object" src="../assets/images/xs/avatar4.jpg" alt="fake_url" />
                                            <div className="media-body">
                                                <span className="name">{msgg.customerName}</span>
                                                <span className="badge badge-outline status" />
                                            </div>
                                        </div>
                                </li>

                                    )):<></>}
                                    
                                </ul>
                            </div>
                            <div className="tab-pane fade" id="groups" role="tabpanel" aria-labelledby="groups-tab">
                                <ul className="right_chat list-unstyled list">
                                    <li className="online">
                                        <a href="/#;">
                                            <div className="media">
                                                <img className="media-object" src="../assets/images/xs/avatar1.jpg" alt="fake_url" />
                                                <div className="media-body">
                                                    <span className="name">PHP Groups</span>
                                                    <span className="message">How is the project coming</span>
                                                    <span className="badge badge-outline status" />
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="online">
                                        <a href="/#;">
                                            <div className="media">
                                                <img className="media-object" src="../assets/images/xs/avatar2.jpg" alt="fake_url" />
                                                <div className="media-body">
                                                    <span className="name">Family Groups</span>
                                                    <span className="message">Update Code</span>
                                                    <span className="badge badge-outline status" />
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="offline">
                                        <a href="/#;">
                                            <div className="media">
                                                <img className="media-object" src="../assets/images/xs/avatar3.jpg" alt="fake_url" />
                                                <div className="media-body">
                                                    <span className="name">Harry McCall</span>
                                                    <span className="message">3 New design bug</span>
                                                    <span className="badge badge-outline status" />
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="offline">
                                        <a href="/#;">
                                            <div className="media">
                                                <img className="media-object" src="../assets/images/xs/avatar4.jpg" alt="fake_url" />
                                                <div className="media-body">
                                                    <span className="name">Friends holic</span>
                                                    <span className="message">Hello All!</span>
                                                    <span className="badge badge-outline status" />
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="online">
                                        <a href="/#;">
                                            <div className="media">
                                                <img className="media-object" src="../assets/images/xs/avatar5.jpg" alt="fake_url" />
                                                <div className="media-body">
                                                    <span className="name">CL City 2</span>
                                                    <span className="message">Add new contact</span>
                                                    <span className="badge badge-outline status" />
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                    {/* </div> */}
                </div>
            </>
        )
    
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar
})

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(AllChart);
import React from "react";
import { Redirect, Route } from "react-router-dom";
import auth from "./auth";

export const ProtectedRoute = ({component: Component, ...rest}) =>{

    return(
        <Route
        {...rest}
        render={props=>{
            if(auth.isAuthneticated()){
                return <Component {...props}/>
            }
            else{
                 return <Redirect to={
                    {
                        pathname: '/signup',
                        state: props.location
                    }
                } />
            }
            
        }}
        >

        </Route>
    )
}
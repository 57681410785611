import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
    ref,
    uploadBytes, uploadString,
    getDownloadURL,deleteObject ,
    listAll,
    list,
    uploadBytesResumable,
  } from "firebase/storage";
  import { collection, addDoc, query, where, getDocs, doc, deleteDoc,setDoc, getDoc, Timestamp } from "firebase/firestore"; 
  import { storage, db } from "../../firebases";
  import { v4 } from "uuid";
import { Link } from 'react-router-dom';
import { MDBSpinner } from 'mdb-react-ui-kit';

class Podcast extends Component {
    state={
        url:'',
        isActive: false,
        allPodcast:[]
    }
    deletePodcast = async(docId) => {
        try{
          
		await deleteDoc(doc(db, "podcast", docId));
		this.getPodcastData()
        }
        catch(e){
            console.log('error in deleting podcast',e );
            alert('Deletion Failed.')
        }

        
        
    }
    updatePodcastStatus = async(docId, activeStatus) => {
		const podcastRef = doc(db, 'podcast', docId);
		await setDoc(podcastRef, { isActive: !activeStatus }, { merge: true });
		this.getPodcastData()
	}
    uploadToFirestore = async(url, isActive,
    docId
    ) => {
        
        const podcastRef = doc(db, 'podcast', docId);
        await setDoc(podcastRef,
            {
                url: url,
                isActive: Boolean(isActive),
                id: docId,
              }
            , { merge: true })
          .then((doc) => {
            console.log('document uploaded', doc);
            alert('Podcast Link uploaded')
          })
          .catch((error) => {
            console.log(error);
            alert('Error while uploading podcast Data')
          });
    }
  
     uploadData = async (imageName, videoName, gifName) => {
        
         const { url,isActive} = this.state;
        const docId = v4() + v4()
        await this.uploadToFirestore(url, isActive,
        docId
        )
        this.setState({
        url: '',
        isActive:false,
        })
        
        this.getPodcastData()
        // video upload code 
      };
      deleteExcercise = async(docId) => {
        this.setState({
            isLoading: true
        })
        const docRef = doc(db, "exercises", docId);
        const docSnap = await getDoc(docRef);
        let videoRef = null
        let imageRef = null
        let gifRef = null
        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            const data = docSnap.data()
            videoRef = data.videoName
            imageRef = data.imageName
            gifRef = data.gifName
          } else {
            this.setState({
                isLoading: false
            })
            // doc.data() will be undefined in this case
            alert("No such document!");
            return
          }
          if(videoRef !== '' || videoRef !== null || videoRef !== null ){
            const desertRef = ref(storage, `excercises/videos/${videoRef}`);
            deleteObject(desertRef).then(() => {
              // File deleted successfully
            }).catch((error) => {
                this.setState({
                    isLoading: false
                })
              console.log('error in deleting video');
            });            
          }
          if(imageRef !== '' || imageRef !== null || imageRef !== null ){
            const desertRef = ref(storage, `excercises/images/${imageRef}`);
            deleteObject(desertRef).then(() => {
              // File deleted successfully
            }).catch((error) => {
                this.setState({
                    isLoading: false
                })
              console.log('error in deleting images');
            });            
          }
          if(gifRef !== '' || gifRef !== null || gifRef !== null ){
            const desertRef = ref(storage, `excercises/gif/${gifRef}`);
            deleteObject(desertRef).then(() => {
              // File deleted successfully
            }).catch((error) => {
                this.setState({
                    isLoading: false
                })
              console.log('error in deleting gif');
            });            
          }
		await deleteDoc(doc(db, "exercises", docId));
		this.getPodcastData()
    }
     convert= (str) => {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
      }
    getDate = (timestamp) => {
        // var timestamp = 1607110465663
       console.log('tttt', timestamp);
        if(!timestamp){
           return ''
        }
        var date = new Date(timestamp.seconds*1000);
        console.log(date.getTime())
        console.log(date)
        return this.convert(date.toString())
    }
      getPodcastData = async() => {
		this.setState({
			isLoading:true
		})
	    let podcastList = [] 
		const q = query(collection(db, "podcast"));
		const querySnapshot = await getDocs(q);
		console.log(querySnapshot.docs);
		querySnapshot.forEach((doc) => {
		// doc.data() is never undefined for query doc snapshots
        podcastList.push(doc.data())
		console.log(doc.id, " => ", doc.data());
		});
		this.setState({
			allPodcast: podcastList,
			isLoading: false
		})
	}
    componentDidMount() {
		this.getPodcastData()
	  }
    
      updateExerciseActiveStatus = async (docId, status) => {
		const programRef = doc(db, 'exercises', docId);
        let data;
        data = { isActive: !status }

		await setDoc(programRef, data , { merge: true });
		this.getPodcastData()
	}
    
    render() {
        const { fixNavbar } = this.props;
        const { image, video, gif, allPodcast, showModal, isLoading } = this.state
        return (
            <>
                <div>
                    <div>
                        <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between align-items-center">
                                    <ul className="nav nav-tabs page-header-tab">
                                        <li className="nav-item"><a style={{color: '#Dac27c'}}className="nav-link active" id="Departments-tab" data-toggle="tab" href="#Departments-list">Podcast</a></li>
                                        {/* <li className="nav-item"><a className="nav-link" id="Departments-tab" data-toggle="tab" href="#Departments-grid">Grid View</a></li> */}
                                    </ul>
                                    <div className="header-action">
                                        <button style={{backgroundColor: '#Dac27c'}}type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal"><i className="fe fe-plus mr-2" />Add</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-body mt-3">
                            <div className="container-fluid">
                                <div className="tab-content mt-3">
                                    <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 style={{color:'#Dac27c'}}className="card-title"><b>Podcast List</b></h3>
                                                {/*  */}
                                            </div>
                                            <div className="card-body">
                                                <div className="table-responsive">
                                                <table className="table table-striped table-hover table-vcenter mb-0">
													<thead>
														<tr>
														<th colSpan={1}className="w60">#</th>
															<th  style={{color: '#DAC27C'}} colSpan={7} className="w60">Podcast Link</th>
															<th  style={{color: '#DAC27C'}} colSpan={2}>Active/Deactive</th>
															<th  style={{color: '#DAC27C'}} colSpan={2} className='w100'>Action</th>
														</tr>
													</thead>
													<tbody>
														{
															allPodcast.length > 0 ? (
																allPodcast.map((podcast, index)=>(
													<tr key={podcast.id}>
														    <td colSpan={1}>
																{index+1}
															</td>
															<td colSpan={7}>
                                                                
                                                                <b  style={{color: '#DAC27C'}} className="mb-0">{podcast.url}</b>
																
															</td>
															<td colSpan={2}>
                                                            <button 
																onClick={()=>this.updatePodcastStatus(podcast.id, podcast.isActive)}
																 className={podcast.isActive? 'btn-success':'btn-danger'}>
                                                                   {podcast.isActive? 'Activate' : 'Deactivate'}
																</button>
                                                            </td>
                                
															<td colSpan={2}>
															
																<button
																    onClick= {()=> this.deletePodcast(podcast.id)}
																	type="button"
																	className="btn btn-icon js-sweetalert"
																	title="Delete"
																	data-type="confirm"
																>
																	<i className="fa fa-trash-o text-danger" />
																</button>
															</td>
														</tr>
																))
															) :
															<h3>No podcast available right now</h3>
														}
													</tbody>
												</table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="Departments-grid" role="tabpanel">
                                        <div className="row clearfix">
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar1.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">John Smith</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">105</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$3100</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar2.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">Maryam Amiri</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">105</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$3100</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar3.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">Fidel Tonn</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">12</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$1800</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar4.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">Frank Camly</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">105</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$3100</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar2.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">Maryam Amiri</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">105</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$3100</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar1.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">John Smith</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">55</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$12,024</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar2.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">Maryam Amiri</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">23</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$5210</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* Modal */}
                    <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 style={{color: '#Dac27c'}} className="modal-title" id="exampleModalLabel">Add Podcast</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row clearfix">
                                        <div className="col-md-12">
                                            <label style={{color: '#Dac27c'}}><b>Podcast Url</b></label>
                                            <div className="form-group">
                                            <input type="text"
                                                value={this.state.url}
                                                onChange={(event) => {
                                                this.setState({
                                                    url: event.target.value
                                                })
                                                }}
                                                 className="form-control" placeholder="Podcast Url" name="img" />                                            </div>
                                        </div>
                                        
                                        <div className="col-md-12">
                                        <label style={{color: '#Dac27c'}}><b>Active/Deactive</b></label>
                                            <div className="form-group">
                                            <select 
                                            value={this.state.isActive}
                                                onChange={(event) => {
                                                this.setState({
                                                    isActive: event.target.value
                                                })
                                                }}
                                            className="form-control show-tick">
															
															<option value={true}>Active</option>
															<option value={false}>Deactive</option>
														</select>
   
                                                                                         </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button type="button"
                                    onClick={()=>this.uploadData()}
                                     className="btn btn-primary">Save changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar
})

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(Podcast);
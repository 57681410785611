// import firebase from 'firebase'
// Import the functions you need from the SDKs you need
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { initializeApp } from "firebase/app";
// import 'firebase/firestore'
// import firebase from './firebases';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDYL2hEhXniW4SxnR6sfkZVdaQB9FPjJKc",
  authDomain: "fitt-elite.firebaseapp.com",
  projectId: "fitt-elite",
  storageBucket: "fitt-elite.appspot.com",
  messagingSenderId: "11124217710",
  appId: "1:11124217710:web:e2934f254be04b97f0937f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore();
const storage = getStorage(app)
export {
   db,
   storage
};
const initialState = {
	loggedIn: false
}

export default (state = initialState, action) => {
	switch (action.type) {
		case 'LOGGED_IN_STATE':
			return {
				...state,
				loggedIn: action.payload
			}
		case 'LOGGED_OUT_STATE':
			return {
				...state,
				loggedIn: action.payload
			}

		default:
			return state
	}
}
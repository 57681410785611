import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
    ref,
    uploadBytes,
    getDownloadURL,deleteObject ,
    listAll,
    list,
    uploadBytesResumable,
  } from "firebase/storage";
  import { collection, addDoc, query, where, getDocs, doc, deleteDoc,setDoc, getDoc, Timestamp } from "firebase/firestore"; 
  import { storage, db } from "../../../firebases";
  import { v4 } from "uuid";
import { Link } from 'react-router-dom';
import { AtomLoader, BarsLoader2 } from 'react-loaders-kit';

class EditRecipes extends Component {
    state={
        image:null,
        imageUrl: null,
        recipeDescription:'',
        name:'',
        month:new Date(),
    }
    
    uploadMedia = async(media, path, docId, type) => {
        var mediaUrl = '';
        if (media == null || media == undefined){
            mediaUrl = ''
            return mediaUrl
        }
        let fileRef = ref(storage, path)
        const uploadTask = uploadBytesResumable(fileRef, media)
        uploadTask.on('state_changed', null,
                (error) => {
                  alert(error);
                },
                async () => {
                  await getDownloadURL(uploadTask.snapshot.ref)
                  .then(async (url) => {
                      let data;
                      if (type === 'image'){
                          data={
                              image: url
                          }
                      }
                      if (type === 'video'){
                        data={
                            video: url
                        }
                    }
                    if (type === 'gif'){
                        data={
                            animationGif: url
                        }
                    }
                      mediaUrl = url
                      await setDoc(doc(db, "recipes", docId), data, { merge: true});
                      const {id} = this.props.match.params
                      this.getRecipe(docId)
                  });
                }
              )
        
       return mediaUrl;
    }
    uploadToFirestore = async(rname,rdescription, rmonth,
    docId,imageUrl
    ) => {
        const monthDate = Timestamp.fromDate(new Date(rmonth));
        const recipeRef = doc(db, 'recipes', docId);
        await setDoc(recipeRef,
            {
                name: rname,
                description: rdescription,
                month: monthDate,
                image: imageUrl,
              }
            , { merge: true })
          .then((doc) => {
            console.log('dddd', doc);
            alert('Record uploaded')
          })
          .catch((error) => {
            console.log(error);
            alert('Error while uploading record')
          });
    }
    deleteFileUsingUrl = async (url, type)=>{
        this.setState({
            showLoader: true
        })
        // Create a reference to the file to delete
        const desertRef = ref(storage, url);
        var { id } = this.props.match.params;
            deleteObject(desertRef).then(async () => {
              // File deleted successfully
              const recipeRef = doc(db, 'recipes', id);
              let field;
              if(type == 'image'){
                  field = {
                      image: ''
                  }
              }
              if(type == 'video'){
                field = {
                    video: ''
                }
            }
            if(type == 'gif'){
                field = {animationGif:''}
            }
           await setDoc(recipeRef,
             field
            , { merge: true })
            })
            .catch((error) => {
            console.log(error);
              alert('error in deleting image');
            }); 
           this.getRecipe(id)    
           this.setState({
            showLoader: false
        })    
    }
  
     uploadData = async (imageName, videoName, gifName) => {
         const { name, recipeDescription, month,
            imageUrl } = this.state;
            this.setState({
                showLoader: true
            })
        let imageUrll;
        const nameOfImage = v4() + imageName
        const { id } = this.props.match.params;
        if(imageUrl !==null && imageUrl !==undefined && imageUrl !=='' ){
            imageUrll = imageUrl; 
        }
        else{
            imageUrll = await this.uploadMedia(imageName, `recipes/images/${nameOfImage}`, id, 'image')
        }
        
        await this.uploadToFirestore(name, recipeDescription,month, 
        id, imageUrll)
        this.setState({
        image: null,
        })
        
        this.getRecipe(id)
        // video upload code 
      };
      getRecipe = async(docId) => {
        const docRef = doc(db, "recipes", docId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            const data = docSnap.data()
            this.setState({
                imageUrl:data.image,
                recipeDescription: data.description,
                name: data.name,
                month: this.getDate(data.month),
            })
          } else {
            // doc.data() will be undefined in this case
            alert("No record found for this document");
            return
          }
    }
    
    componentDidMount() {
        const { id } = this.props.match.params;
        this.getRecipe(id)
	  }
    
      convert= (str) => {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(),mnth, day].join("-");
      }
    getDate = (timestamp) => {
        // var timestamp = 1607110465663
        if(!timestamp){
           return ''
        }
        var date = new Date(timestamp.seconds*1000);
        console.log(date.getTime())
        console.log(date)
        return this.convert(date.toString())
    }
    render() {
        const { fixNavbar } = this.props;
        const { image, video, gif, allExcercises, showLoader, imageUrl, videoUrl,gifUrl } = this.state
        return (
            <>
            
            <div>
									<div className="card">
										<div className="card-body">
											<div className="row clearfix">
												<div className="col-lg-12 col-md-12 col-sm-12">
                                                <label style={{color: '#Dac27c'}}><b>Recipe Description</b></label>
													<div className="form-group">
                                                    <textarea
                                                    value={this.state.recipeDescription}
                                            onChange={(event) => {
                                                this.setState({
                                                    recipeDescription:event.target.value
                                                })
                                                }} 
                                            className="form-control" placeholder="Excercise Description" 
                                            id="w3review" name="w3review" rows="4" cols="50"></textarea>
													</div>
												</div>
												<div className="col-lg-12 col-md-12 col-sm-12">
                                                <label style={{color: '#Dac27c'}} ><b>Recipe Name</b></label>
													<div className="form-group">
                                                    <input type="text"
                                                    value={this.state.name}
                                                onChange={(event) => {
                                                this.setState({
                                                    name: event.target.value
                                                })
                                                }}
                                                 className="form-control" placeholder="Recipe Date" name="img" /> 
													</div>
												</div>
												<div className="col-md-12 col-sm-12">
													<div className="form-group">
                                                    <label style={{color: '#Dac27c'}}><b>Recipe Date</b></label>  
                                                    <input type="date"
                                                value={this.state.month}
                                                onChange={(event) => {
                                                this.setState({
                                                    month: event.target.value
                                                })
                                                }}
                                                 className="form-control" placeholder="Month" name="img" />    
													</div>
												</div>
												<div className="col-md-4 col-sm-12">
                                                <label style={{color: '#Dac27c'}}>Upload Image</label>
													<div className="form-group">
                                                    <input type="file"
                                                accept="image/*"
                                                disabled={imageUrl !== '' && imageUrl !== undefined && imageUrl !== null ?true:false} 
                                                onChange={(event) => {
                                                this.setState({
                                                    image:event.target.files[0]
                                                })
                                                }}
                                                 className="form-control" placeholder="Upload Image" name="img" />
													</div>
												</div>
                                                <div className='col-md-12 col-sm-12'>
                                                <b style={{color: '#Dac27c'}}>Note: Please Delete existing media i.e Image/Video/Gif if you want to update media. </b>
                                                <br /><br /> 
                                                </div>
                                                <div className="col-md-11 col-sm-11">
                                                <b style={{color: '#Dac27c'}}>Image Url: &nbsp;&nbsp;</b> {imageUrl !==''?<><a href={imageUrl}>
                                                {imageUrl}
                                                </a>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <button
                                                onClick= {()=>this.deleteFileUsingUrl(imageUrl, 'image')}
                                                    type="button"
                                                    className="btn btn-icon js-sweetalert"
                                                    title="Delete"
                                                    data-type="confirm"
                                                >
                                                    <i className="fa fa-trash-o text-danger" />
                                                </button>
                                                </>
                                                : 'None'}
                                                </div>
                                            
												<div className="col-lg-12 col-md-12 col-sm-12">
													<button
                                                    style={{backgroundColor: '#Dac27c'}}
                                                    onClick={()=>this.uploadData(image, video, gif)}
                                                     type="button" className="btn btn-primary">
														Update
													</button>
												</div>	
												</div>
											</div>
										</div>
								</div>
            
            
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar
})

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(EditRecipes);
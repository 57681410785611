import React, { Component, useEffect, useState } from 'react'
import { connect } from 'react-redux';
// import { doc, onSnapshot } from "firebase/firestore";
import { collection, query, where, onSnapshot, FieldValue,setDoc, doc, serverTimestamp, orderBy, getDoc } from "firebase/firestore";
// import { collection, orderBy, query, where, getDocs, doc, deleteDoc,setDoc, getDoc } from "firebase/firestore"; 
import { storage, db } from "../../firebases";
// import { useCollectionData } from 'react-firebase-hooks/firestore';
import { v4 } from 'uuid';
import { useParams } from "react-router-dom";

const AppChart = (props)=> {

    const {name, email, id} = useParams();
    const convertTimestamp =(timestamp)=> {
        if(timestamp){
        let date = timestamp.toDate();
        let mm = date.getMonth();
        let dd = date.getDate();
        let yyyy = date.getFullYear();
        let hours = date.getHours()
        let mins = date.getMinutes()
    
        date = mm + '/' + dd + '/' + yyyy +' '+ `${hours}:${mins}`;
        return date;
        }
        return 
    }
       
        const getSnapShot = async ()=>{
        const q = query(collection(db, `chats/${id}/messages`), orderBy("createdAt"));
        const subscribe = onSnapshot(q, async (querySnapshot) => {
        const msg = [];
        querySnapshot.forEach((doc) => {
            msg.push(doc.data());
        });
        setMessages(msg)
        });
        setReadReceipt()
    }
    
    const setReadReceipt = async ()=>{
        const totalMessages = messages.length
        if(totalMessages>0 && messages[totalMessages-1].sentBy ==='customer'){
            const docRef = doc(db, "chats", id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const data = docSnap.data()
                if(data.isRead === true){
                    return 
                }
            }
            const messageMetaRef = doc(db, `chats`,id);
            const readReceiptUpdate = await setDoc(messageMetaRef, {
              isRead:true
            }, {merge: true})
        }
    }
    

    
    const [messages, setMessages] = useState([])
    const [allMessages, setAllMessages] = useState([])
    const [messageValue, setMessageValue] = useState('')
    const [customerName, setCustomerName] = useState('')
    const [customerEmail, setCustomerEmail] = useState('')
    
    useEffect(() => {
        getSnapShot()
      }, [messages])
    
    const sendMessage = async () => {
        if(!id || id.trim() !== ''){
    const docId= v4()
    
    const messageRef = doc(db, `chats/${id}/messages`, docId);
        await setDoc(messageRef,
            {
                message: messageValue,
                sentBy: 'admin',
                createdAt: serverTimestamp(),
                              }
            , { merge: true })
          .then(async (abc) => {
            const messageMetaRef = doc(db, `chats`,id);
            const msgTimeUpdate = await setDoc(messageMetaRef, {
              lastMessageDateTime: serverTimestamp(),
              isRead:false,
              lastSender: 'admin'
            }, {merge: true})
             return msgTimeUpdate 
          }).then(msgTime=>{
            console.log('message sent and time updated')
          })
          .catch((error) => {
            console.log(error);
            alert('Error while sending a message')
          });

         setMessageValue('');
        }
    }

      //  const { fixNavbar } = this.props;
        return (
            <>
                <div className="section-light py-3 chat_app">
                    <div className="container-fluid">
                        
                        <div className="row">
                            <div className="col-lg-8 col-md-12">
                                <div className="card bg-none b-none">
                                    <div className="card-header bline pt-1">
                                        <h3 className="card-title">{name} <small>{email}</small></h3>
                                        {/* <div className="card-options">
                                            <a href="/#" className="p-1 chat_list_btn"><i className="fa fa-align-right" /></a>
                                            <a href="/#" className="p-1"><i className="fa fa-plus" /></a>
                                            <a href="/#" className="p-1"><i className="fa fa-cog" /></a>
                                            <a href="/#" className="p-1"><i className="fa fa-refresh" /></a>
                                        </div> */}
                                    </div>
                                    <div className="chat_windows">
                                        <ul className='mb-0'>
                                        {
                                            messages.length>0?messages.map((msg, index)=>(
                                              <>
                                              <div key={index}>
                                                  {
                                                      msg.sentBy ==='admin'?(<li className="my-message">
                                                <div className="message">
                                                    <p className="bg-light-gray">{msg.message}</p>
                                                    <span className="time">{convertTimestamp(msg.createdAt)}</span>
                                                </div>
                                            </li>):(<li className="other-message">
                                                    <div className="message">
                                                    <p className="bg-light-blue">{msg.message}</p>
                                                    <span className="time">{convertTimestamp(msg.createdAt)}</span>
                                                </div>
                                            </li>)
                                                  }
                                              </div>    
                                            </>     
                                            
                                            )):<></>
                                        }
                                        </ul>
                                        {/* <ul className="mb-0">
                                            <li className="other-message">
                                                <img className="avatar mr-3" src="../assets/images/xs/avatar2.jpg" alt="avatar" />
                                                <div className="message">
                                                    <p className="bg-light-blue">Are we meeting today?</p>
                                                    <span className="time">10:10 AM, Today</span>
                                                </div>
                                            </li>
                                            <li className="my-message">
                                                <div className="message">
                                                    <p className="bg-light-gray">Project has been already finished and I have results to show you.</p>
                                                    <span className="time">10:17 AM, Today</span>
                                                </div>
                                            </li>
                                        </ul> */}
                                        
                                        <div className="chat-message clearfix" >
                                        <div style={{position: 'relative',float: 'right'}}>
                                        <a 
                                            style={{cursor: 'pointer'}}
                                            onClick={()=>!messageValue ||  messageValue.trim()==''?{}:sendMessage()} disabled= {!messageValue ||  messageValue.trim()==''? true:false}><i className="icon-paper-plane" /></a>
                                        </div>
                                            <div className="input-group mb-0">
                                                <textarea onChange={(e)=>setMessageValue(e.target.value)}  className="form-control" placeholder="Enter text here..." />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="chat_list section-white" id="users">
                        <a href="/#" className="chat_list_btn float-right"><i className="fa fa-align-right" /></a>
                        <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="users-tab" data-toggle="tab" href="#users-list" role="tab" aria-controls="users-list" aria-selected="true">Users</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="groups-tab" data-toggle="tab" href="#groups" role="tab" aria-controls="groups" aria-selected="false">Groups</a>
                            </li>
                        </ul>
                        
                        
                    </div> */}
                </div>
            </>
        )
    
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar
})

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(AppChart);
import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
    ref,
    uploadBytes, uploadString,
    getDownloadURL,deleteObject ,
    listAll,
    list,
  } from "firebase/storage";
  import { collection, orderBy, query, where, getDocs, doc, deleteDoc,setDoc, getDoc } from "firebase/firestore"; 
  import { storage, db } from "../../../firebases";
  import { v4 } from "uuid";
import { Link } from 'react-router-dom';

class AddRoutine extends Component {
    state={
        showModal:false,
        image:null,
        programName: '',
        programDesc:'',
        isActive: true,
        isPopular: true,
        recommendedFor: 'Male',
        smallDesc: '',
        days: 0,
        isLoading: false,
        intensityLevel: 0,
        allPrograms: [],
        allWorkoutDays:[],
        selectedDay:'',
        daysList: [],
        dayNumber: 0,
        met:0,
        totalDurationInMinutes:0,
        totalDurationInSeconds:0, 
        totalExercises: 0
    }

    generateArrayOfNumberOfDays = ()=>{
        const { days: noDay } = this.props.match.params;
        var numbers = [];
        for (var i = 1; i <=noDay ; i++) {
            numbers.push(i);
        }
        this.setState({
            daysList: numbers
        })
    }
       // update status of popular and active
    updateProgramActiveStatus = async(docId, status, type) => {
		const programRef = doc(db, 'programs', docId);
        let data;
        if(type === 'active'){
           data = { isActive: !status }
        }
        else if (type === 'popular'){
            data = { isPopular: !status }
        }
		await setDoc(programRef, data , { merge: true });
		this.getDays()
	}
    // upload media
    uploadToFirestore = async(dayNumber, met, totalDuration, totalExercises,
    docId
    ) => {
        const { id } = this.props.match.params
        const excerciseRef = doc(db, `programs/${id}/workouts`, docId);
        await setDoc(excerciseRef,
            {
                dayIndex: Number(dayNumber),
                id: docId,
                met: Number(met),
                totalDuration: Number(totalDuration),
                totalExercises: 0
                              }
            , { merge: true })
          .then((doc) => {
            alert('Day Created')
          })
          .catch((error) => {
            console.log(error);
            alert('Error while creating a day')
          });
    }
    
    checkIfDayExists =  async() =>{
        const { id } =this.props.match.params;
        const q = query(collection(db, `programs/${id}/workouts`), where("dayIndex", "==", Number(this.state.dayNumber)));
        const querySnapshot = await getDocs(q);
        console.log(querySnapshot.docs.length);
        if(querySnapshot.docs.length>0){
            return true
        }
        return false
}
     uploadData = async () => {
         const { dayNumber, met, totalDurationInMinutes, totalDurationInSeconds, totalExercises} = this.state;
         if(dayNumber == 0){
             alert('Day Number can not be 0');
             return
         }
        const dayExists = await this.checkIfDayExists()
        console.log('dddd', dayExists);
        if (dayExists){
            alert('Day already Exists');
             return
        }
        const totalDuration = (Number(totalDurationInMinutes) * 60) + Number(totalDurationInSeconds)
        const docId = v4() + v4()
        await this.uploadToFirestore(dayNumber, met, totalDuration, 
        totalExercises, docId
        )
        this.setState({
        dayNumber: 0,
        met:'',
        totalDurationInMinutes: 0,
        totalDurationInSeconds:0,
        totalExercises: 0,
        })
        
        this.getDays()
        // video upload code 
      };
      deleteDay = async(docId) => {
        try{
            const {id} = this.props.match.params
		await deleteDoc(doc(db, `programs/${id}/workouts`, docId));
		this.getDays()
        }
        catch(e){
            console.log('error in deleting program',e );
            alert('Deletion Failed.')
        }

        
        
    }
    
      getDays = async() => {
		this.setState({
			isLoading:true
		})
        const { id } = this.props.match.params
	    let daysInProgram = []
		const q = query(collection(db, `programs/${id}/workouts`), orderBy("dayIndex"));
		const querySnapshot = await getDocs(q);
		console.log(querySnapshot.docs);
		querySnapshot.forEach((doc) => {
		// doc.data() is never undefined for query doc snapshots
        const duration = doc.data().totalDuration;
        const minutes = Math.floor(duration/60)
        const seconds = Math.round(((duration/60)-Math.floor(duration/60))*60)
        let data={...doc.data()}
        data['minutes'] = minutes
        data['seconds'] =seconds
        // doc.data().minutes = minutesar
        // doc.data().seconds = seconds
        daysInProgram.push(data)
		console.log(doc.id, " => ", data);
        // this.convertMinAndSecond(doc.data().totalDuration)
		});
		this.setState({
			allWorkoutDays: daysInProgram,
			isLoading: false
		})
	}
    convertMinAndSecond = (duration)=>{
     console.log('min', Math.floor(duration/60) );
     const sec= ((duration/60)-Math.floor(duration/60))*60
     console.log('sec',Math.ceil(sec))
    }

    componentDidMount() {
		this.getDays()
        this.generateArrayOfNumberOfDays()
	  }
    
    render() {
        const { fixNavbar } = this.props;
        const { id: programId } = this.props.match.params
        const { image, allPrograms, recommendedFor, daysList,allWorkoutDays} = this.state
        return (
            <>
                <div>
                    <div>
                        <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between align-items-center">
                                    <ul className="nav nav-tabs page-header-tab">
                                        {/* <li className="nav-item"><a className="nav-link active" id="Departments-tab" data-toggle="tab" href="#Departments-list">List View</a></li>
                                        <li className="nav-item"><a className="nav-link" id="Departments-tab" data-toggle="tab" href="#Departments-grid">Grid View</a></li> */}
                                    </ul>
                                    <div className="header-action">
                                        <button  style={{backgroundColor: '#DAC27C'}} type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal"><i className="fe fe-plus mr-2" />Add</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-body mt-3">
                            <div className="container-fluid">
                                <div className="tab-content mt-3">
                                    <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 className="card-title">Days List</h3>
                                                {/* <div className="card-options">
                                                    <form>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control form-control-sm" placeholder="Search something..." name="s" />
                                                            <span className="input-group-btn ml-2"><button className="btn btn-icon" type="submit"><span className="fe fe-search" /></button></span>
                                                        </div>
                                                    </form>
                                                </div> */}
                                            </div>
                                            <div className="card-body">
                                                <div className="table-responsive">
                                                <table className="table table-striped table-hover table-vcenter mb-0">
													<thead>
														<tr>
														<th  style={{color: '#DAC27C'}} colSpan={1} className="w60">#</th>
															<th  style={{color: '#DAC27C'}} colSpan={2} className="w60">Day</th>
															<th  style={{color: '#DAC27C'}} colSpan={2}>Met</th>
															<th  style={{color: '#DAC27C'}} colSpan={2}>Total Duration</th>
															<th  style={{color: '#DAC27C'}} colSpan={2}>Total Exercises</th>
															<th  style={{color: '#DAC27C'}} colSpan={3} className='w100'>Action</th>
														</tr>
													</thead>
													<tbody>
														{
															allWorkoutDays.length > 0 ? (
																allWorkoutDays.map((day, index)=>(
													<tr colSpan={1} key={day.id}>
														    <td>
																{index+1}
															</td>
															<td colSpan={2}>
																<h6  style={{color: '#DAC27C'}} className="mb-0">Day {day.dayIndex}</h6>
																
															</td>
															<td colSpan={2}>{day.met}</td>
															<td colSpan={2}>{`${day.minutes}mins ${day.seconds} seconds`}</td>
                                                            <td colSpan={2}>{day.totalExercises}</td>
															<td colSpan={3}>
																<button
																	type="button"
                                                            
																	className="btn btn-icon"
																	title="Edit"
																>
                                                            <Link to={`/add/day/${programId}/${day.id}`}>
																<i className="fa fa-edit" />
                                                            </Link>
																</button>
																<button
																    onClick= {()=> this.deleteDay(day.id)}
																	type="button"
																	className="btn btn-icon js-sweetalert"
																	title="Delete"
																	data-type="confirm"
																>
																	<i className="fa fa-trash-o text-danger" />
																</button>
															</td>
														</tr>
																))
															) :
															<h3>Create Day Please</h3>
														}
													</tbody>
												</table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="Departments-grid" role="tabpanel">
                                        <div className="row clearfix">
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar1.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">John Smith</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">105</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$3100</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar2.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">Maryam Amiri</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">105</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$3100</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar3.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">Fidel Tonn</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">12</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$1800</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar4.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">Frank Camly</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">105</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$3100</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar2.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">Maryam Amiri</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">105</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$3100</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar1.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">John Smith</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">55</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$12,024</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar2.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">Maryam Amiri</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">23</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$5210</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* Modal */}
                    <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="row clearfix">
                                    <div className="col-md-12">
                                            
                                            <div className="form-group">
                                            <label  style={{color: '#DAC27C'}}><b>Add Day</b></label>
                                            <input type="number"
                                                value={this.state.dayNumber}
                                                min="1" max="100000"
                                                onChange={(event) => {
                                                this.setState({
                                                    dayNumber: event.target.value
                                                })
                                                }}
                                                 className="form-control" placeholder="Day Number" name="program" />     
   
                                            </div>
                                    </div>
                                    <div className="col-md-12">
                                            
                                            <div className="form-group">
                                            <label><b>MET</b></label>
                                            <input type="number"
                                            min="0" max="100000"
                                                value={this.state.met}
                                                onChange={(event) => {
                                                this.setState({
                                                    met: event.target.value
                                                })
                                                }}
                                                 className="form-control" placeholder="MET" name="program" />     
   
                                            </div>
                                    </div>
                                    <div className="col-md-4">
                                            
                                            <div className="form-group">
                                            <label><b>Time/Minutes</b></label>
                                            <input type="number"
                                            min="1" max="1000"
                                                value={this.state.totalDurationInMinutes}
                                                onChange={(event) => {
                                                this.setState({
                                                    totalDurationInMinutes: event.target.value
                                                })
                                                }}
                                                 className="form-control" placeholder="Total Duration In Minutes" name="program" />     
   
                                            </div>
                                    </div>
                                    <div className="col-md-4">
                                            
                                            <div className="form-group">
                                            <label><b>Time/Seconds</b></label>
                                            <input type="number"
                                            min="1" max="1000"
                                                value={this.state.totalDurationInSeconds}
                                                onChange={(event) => {
                                                this.setState({
                                                    totalDurationInSeconds: event.target.value
                                                })
                                                }}
                                                 className="form-control" placeholder="Total Duration In Seconds" name="program" />     
   
                                            </div>
                                    </div>
                                    {/* <div className="col-md-12">
                                            
                                            <div className="form-group">
                                            <label><b>Total Exercises</b></label>
                                            <input type="number"
                                                value={this.state.totalExercises}
                                                onChange={(event) => {
                                                this.setState({
                                                    totalExercises: event.target.value
                                                })
                                                }}
                                                 className="form-control" placeholder="Total Exercises" name="program" />     
   
                                            </div>
                                    </div> */}
                                        
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button  style={{backgroundColor: '#DAC27C'}} type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button type="button"
                                    style={{backgroundColor: '#DAC27C'}}
                                    onClick={()=>this.uploadData()}
                                     className="btn btn-primary">Save changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar
})

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(AddRoutine);
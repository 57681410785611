import React, { Component } from 'react';
import { connect } from 'react-redux';
import './App.css';
import Layout from './components/Shared/Layout';
import Login from './components/Authentication/login';
import SignUp from './components/Authentication/signup';
import ForgotPassword from './components/Authentication/forgotpassword';
import NotFound from './components/Authentication/404';
import InternalServer from './components/Authentication/500';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import firebase from './firebases'
import authReducer from './reducers/authReducer';
import { ProtectedRoute } from './protected.route';

class App extends Component {
	constructor(props){
		super(props)
	}
	render() {
		const { darkMode, boxLayout, darkSidebar, iconColor, gradientColor, rtl, fontType } = this.props
		console.log('ssssss',this.props.loggedIn);
		// if(localStorage.getItem('jwttoken')==null && localStorage.getItem('jwttoken')==undefined &&
		// localStorage.getItem('jwttoken')==''){
		// 	setInterval(()=>{
		// 		console.log('fun running');
		// 		if(localStorage.getItem('jwttoken')==null && localStorage.getItem('jwttoken')==undefined &&
		// localStorage.getItem('jwttoken')==''){
		// 	window.location.href = '/signup'
		// }
		// 	}, 1000);
		// 	return
		// }

		return (
			<div className={`${darkMode ? "dark-mode" : ""}${darkSidebar ? "sidebar_dark" : ""} ${iconColor ? "iconcolor" : ""} ${gradientColor ? "gradient" : ""} ${rtl ? "rtl" : ""} ${fontType ? fontType : ""}${boxLayout ? "boxlayout" : ""}`}>
				<Router>
					<Switch>

						<Route path="/signup" component={SignUp} />
						{/* login path is only for the developers to help the admin create new account */}
						{/* <Route path="/login" component={Login} /> */}
						{/* <Route path="/forgotpassword" component={ForgotPassword} />
						<Route path="/notfound" component={NotFound} />
						<Route path="/internalserver" component={InternalServer} /> */}
						{/* <Route component={Layout} /> */}
						<ProtectedRoute
						exact
						component={Layout}
						></ProtectedRoute>
						{/* {
							localStorage.getItem('jwttoken')!==null && localStorage.getItem('jwttoken')!==undefined &&
							localStorage.getItem('jwttoken')!==''
							? <Route component={Layout} /> : <></>
						} */}
					</Switch>
				</Router>
			</div>
		);
		// let navHeader = this.state.visibility ? <Layout /> : <Login />;
		// return (
		//   <div>
		//       {navHeader}
		//   </div>
		// )
	}
}
const mapStateToProps = state => ({
	darkMode: state.settings.isDarkMode,
	darkSidebar: state.settings.isDarkSidebar,
	iconColor: state.settings.isIconColor,
	gradientColor: state.settings.isGradientColor,
	rtl: state.settings.isRtl,
	fontType: state.settings.isFont,
	boxLayout: state.settings.isBoxLayout,
	loggedInState: state.authReducer.loggedIn
})

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(App)
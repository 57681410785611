import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
    ref,
    uploadBytes,
    getDownloadURL,deleteObject ,
    listAll,
    list,
  } from "firebase/storage";
  import { collection, query, where, getDocs, doc, deleteDoc,setDoc, getDoc,orderBy } from "firebase/firestore"; 
  import { storage, db } from "../../../firebases";
  import { v4 } from "uuid";
import { Link } from 'react-router-dom';
import { AtomLoader, BarsLoader2 } from 'react-loaders-kit';

class AddDay extends Component {
    state={
        noOfDay:'day1',
        exerciseType: 'duration',
        allExcercises: [],
        dayExercises: [], 
        selectedExercise: null,
        exerciseValue: 0,
        sortIndex: 1, 
        isEditingMode: false,
        exerciseCount: 0,
        met:0,
        dayNumber:0,
        totalDurationInMinutes:0,
        totalDurationInSeconds:0,
        exerciseDataId: null
        
    }
    
    getExcercises = async() => {
		this.setState({
			isLoading:true
		})
	    let excerciseList = []
		const q = query(collection(db, "exercises"), orderBy("name"));
		const querySnapshot = await getDocs(q);
		console.log(querySnapshot.docs);
		querySnapshot.forEach((doc) => {
		// doc.data() is never undefined for query doc snapshots
        excerciseList.push(doc.data())
		console.log(doc.id, " => ", doc.data());
		});
		this.setState({
			allExcercises: excerciseList,
            selectedExercise: excerciseList.length>0? excerciseList[0].id : null,
			isLoading: false
            
		})
	}
    setTotalExerciseCount = async () => {
        const { pid: programId, wid: workoutDayId } = this.props.match.params
        const {dayExercises} = this.state;
        let data = {
            totalExercises: dayExercises.length
        }
        const docRef = doc(db, `programs/${programId}/workouts/${workoutDayId}`);
        await setDoc(docRef, data, {merge: true})

    }
    getExcercisesForTheDay = async() => {
		this.setState({
			isLoading:true
		})
	    let excerciseForTheDayList = []
        const { pid: programId, wid: workoutDayId } = this.props.match.params
		const q = query(collection(db, `programs/${programId}/workouts/${workoutDayId}/exercises`), orderBy("sortIndex"));
        const docRef = doc(db, `programs/${programId}/workouts/${workoutDayId}`);
        const docSnap = await getDoc(docRef); 
        if (docSnap.exists()) {
            this.setState({
                exerciseCount: docSnap.data().totalExercises
            })
            console.log('totalExercises', docSnap.data().totalExercises)
        }
		const querySnapshot = await getDocs(q);
		console.log(querySnapshot.docs);
		querySnapshot.forEach((doc) => {
		// doc.data() is never undefined for query doc snapshots
        excerciseForTheDayList.push(doc.data())
		console.log(doc.id, " => ", doc.data());
		});
		this.setState({
			dayExercises: excerciseForTheDayList,
            exerciseCount: excerciseForTheDayList.length            
		})
	}
    getExerciseMetaData = async ()=>{
        const { pid: programId, wid: workoutDayId } = this.props.match.params
		const docRef = doc(db, `programs/${programId}/workouts/${workoutDayId}`);
        const docSnap = await getDoc(docRef); 
        if (docSnap.exists()) {
            const data = docSnap.data()
            const duration = data.totalDuration
            var result = ((duration/60) - Math.floor(duration/60)) === 0;
            let durationInSeconds=0
            if(!result){
                durationInSeconds = Number(String(duration/60).split('.')[1])
                console.log('a',durationInSeconds)
                durationInSeconds= Math.floor(Number(`0.${durationInSeconds}`)*60)
                console.log('b',durationInSeconds)
                console.log('kkkk', durationInSeconds);
            }
            console.log('ddd', Number(String(duration/60).split('.')[1])); 
            const timeInMin = Math.floor(data.totalDuration/60)
            console.log();
            this.setState({
                met:data.met,
                dayNumber:data.dayIndex,
                totalDurationInMinutes: timeInMin,
                totalDurationInSeconds:durationInSeconds
            })
            console.log('exe data', data, durationInSeconds )
        }
    }
    updateMetaExercises= async ()=>{
        const { pid: programId, wid: workoutDayId } = this.props.match.params
        const {met, dayNumber, totalDurationInMinutes, totalDurationInSeconds} = this.state
        const totalTime = Number(totalDurationInMinutes)*60 + Number(totalDurationInSeconds)
        const excerciseRef = doc(db, `programs/${programId}/workouts`, workoutDayId);
            await setDoc(excerciseRef,
                {
                  dayIndex: Number(dayNumber),
                  met: Number(met),
                  totalDuration: totalTime,
                }
                , { merge: true }).then(docss=>{
                    alert('Data updated')
                    this.getExerciseMetaData()
                }).catch(err=>{
                    alert(err.message)
                })
    }
    componentDidMount() {
        
		this.getExcercises()
        this.getExcercisesForTheDay()
        this.getExerciseMetaData()
	  }
    
      EditingExercise = async (exercise)=>{
          await this.uploadToFirestore('edit')
          this.setState({
            isEditingMode: false,
            selectedExercise: this.state.allExcercises[0].id,
            exerciseValue: 0,
            sortIndex: 1
         })
      }
      uploadToFirestore = async(type) => {
            const {selectedExercise, exerciseValue, sortIndex, isEditingMode, allExcercises, exerciseDataId} = this.state
            let data;
            const exerciseDataDocId= v4() + v4()
            if(isEditingMode){
                data = {
                    sortIndex:Number(sortIndex),
                    value: Number(exerciseValue)
                }
            }
            
            else{
                
              data = {
                exerciseId: selectedExercise,
                sortIndex:Number(sortIndex),
                value: Number(exerciseValue),
                id: exerciseDataDocId
            }
            }
            const docIdOfExercise =  isEditingMode? exerciseDataId : exerciseDataDocId
            const { pid: programId, wid: workoutDayId } = this.props.match.params
            const excerciseRef = doc(db, `programs/${programId}/workouts/${workoutDayId}/exercises`, docIdOfExercise);
            await setDoc(excerciseRef,
                data
                , { merge: true })
              .then(async (doc) => {
                
                await this.getExcercisesForTheDay()
                await this.setTotalExerciseCount()
                this.setState({
                   selectedExercise: allExcercises[0].id,
                   exerciseValue: 0,
                   sortIndex: 1 
                })
                alert(type =='edit'?'Exercise Updated':'Exercise for the day Created')
              })
              .catch((error) => {
                console.log(error);
                alert('Error while creating an Exercise for the day!')
              });
        }
        deleteExerciseFromTheDay = async(docId) => {
            try{
                const { pid: programId, wid: workoutDayId } = this.props.match.params
            await deleteDoc(doc(db,  `programs/${programId}/workouts/${workoutDayId}/exercises`, docId));
            await this.getExcercisesForTheDay()
            await this.setTotalExerciseCount()
            }
            catch(e){
                console.log('error in deleting exercise from the day',e );
                alert('error in deleting exercise from the day')
            }
    
            
            
        }
    
    render() {
        const { fixNavbar } = this.props;
        const { allExcercises, dayExercises, isEditingMode } = this.state
        const { id, days: daysInProgram } = this.props.match.params;
        return (
            <>
             <div>
									<div className="card">
										<div className="card-body">
											<div className="row clearfix">
                                            <div className="col-md-2">
                                            
                                            <div className="form-group">
                                            <label><b>Add Day</b></label>
                                            <input type="number"
                                              min='1' max='100000'
                                                value={this.state.dayNumber}
                                                onChange={(event) => {
                                                this.setState({
                                                    dayNumber: event.target.value
                                                })
                                                }}
                                                 className="form-control" placeholder="Day Number" name="program" />     
   
                                            </div>
                                    </div>
                                    <div className="col-md-2">
                                            
                                            <div className="form-group">
                                            <label><b>MET</b></label>
                                            <input type="number"
                                            min="0" max="3"
                                                value={this.state.met}
                                                onChange={(event) => {
                                                this.setState({
                                                    met: event.target.value
                                                })
                                                }}
                                                 className="form-control" placeholder="MET" name="program" />     
   
                                            </div>
                                    </div>
                                    <div className="col-md-2">
                                            
                                            <div className="form-group">
                                            <label><b>Time/Minutes</b></label>
                                            <input type="number"
                                            min="0" max="1000"
                                                value={this.state.totalDurationInMinutes}
                                                onChange={(event) => {
                                                this.setState({
                                                    totalDurationInMinutes: event.target.value
                                                })
                                                }}
                                                 className="form-control" placeholder="Total Duration In Minutes" name="program" />     
   
                                            </div>
                                    </div>
                                    <div className="col-md-2">
                                            
                                            <div className="form-group">
                                            <label><b>Time/Seconds</b></label>
                                            <input type="number"
                                            min="0" max="1000"
                                                value={this.state.totalDurationInSeconds}
                                                onChange={(event) => {
                                                this.setState({
                                                    totalDurationInSeconds: event.target.value
                                                })
                                                }}
                                                 className="form-control" placeholder="Total Duration In Seconds" name="program" />     
   
                                            </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12">
													<button
                                                    onClick={()=> this.updateMetaExercises()}
                                                     type="button" className="btn btn-primary">
														Update Day Data
													</button>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <br />
                                    </div>

                                            <div className="col-md-3 col-sm-3">
													<div className="form-group">
                                                    <label><b>Select Exercise</b></label>  
                                                    <select
                                                    disabled= {isEditingMode? true : false}
                                            value={this.state.selectedExercise}
                                            onChange={(event) => {
                                                this.setState({
                                                    selectedExercise:event.target.value
                                                })
                                                }}
                                             className="form-control show-tick">
															{
                                                                allExcercises.length>0? allExcercises.map(exercise=>(
                                                                    <option value={exercise.id}>{exercise.name}</option>
                                                                ))
                                                                : <option value={null}>No Exercise Available</option>
                                                            } 
														
														</select>    
													</div>
												</div>
                                                <div className="col-md-2 col-sm-2">
                                                <div className="form-group">
                                                    <label><b>Exercise type</b></label>
                                                    <div>
                                                    
                                                        {allExcercises.length>0?
                                                            allExcercises.filter(allEx=> allEx.id === this.state.selectedExercise)[0].type : <></>
                                                        }
                                            
                                                    </div>
                                                </div>

                                                </div>
												<div className="col-lg-3 col-md-3 col-sm-3">
													<div className="form-group">
                                                    <label><b>Repetition/Duration</b></label>
														<input
                                                        min="0" max="10000"
                                                        value={this.state.exerciseValue}
                                                            onChange={(event) => {
                                                this.setState({
                                                    exerciseValue:event.target.value
                                                })
                                                }}
															type="number"
															className="form-control"
															placeholder="value"
														/>
													</div>
												</div>
                                                <div className="col-lg-3 col-md-3 col-sm-3">
													<div className="form-group">
                                                    <label><b>Sort Index</b></label>
														<input
                                                        min="1" max="3"
                                                        value={this.state.sortIndex}
                                                            onChange={(event) => {
                                                this.setState({
                                                    sortIndex:event.target.value
                                                })
                                                }}
															type="number"
															className="form-control"
															placeholder="sort index"
														/>
													</div>
												</div>
												<div className="col-lg-12 col-md-12 col-sm-12">
													<button
                                                    onClick={()=> isEditingMode? this.EditingExercise() :this.uploadToFirestore()}
                                                     type="button" className="btn btn-primary">
														{isEditingMode? 'Update' : 'Add'}
													</button>
												</div>	
												</div>
											</div>
										</div>
                                        <hr aria-atomic/>
                                        <div className="card">
										<div className="card-body">
											<div className="row clearfix">
                                              	</div>
                                                 <h4> <b>Exercises </b></h4>
                                                 <br />
                                                 {dayExercises.length>0? dayExercises.map(ex=>(
                                                     <>
                                                     <div className="col-lg-8 col-md-8 col-sm-7">
                                                     Exercise name:&nbsp; &nbsp;<b>{allExcercises.filter(allEx=> allEx.id == ex.exerciseId)[0].name}</b>
                                                     &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                                                     <button
                                                        onClick= {async ()=> {
                                                            this.setState({
                                                            isEditingMode: true,
                                                            exerciseValue: ex.value,
                                                            sortIndex: ex.sortIndex,
                                                            selectedExercise:ex.exerciseId,
                                                            exerciseDataId: ex.id
                                                        })
                                                        }}
                                                        type="button"
                                                        className="btn btn-icon"
														title="Edit"
                                                    >
                                                    <i className="fa fa-edit" />
                                                    </button>
                                                     <button
																    onClick= {()=> this.deleteExerciseFromTheDay(ex.id)}
																	type="button"
																	className="btn btn-icon js-sweetalert"
																	title="Delete"
																	data-type="confirm"
																>
																	<i className="fa fa-trash-o text-danger" />
                                                    </button>
                                                   
                                                     <br/>
                                                     Exercise Type:{' '}<b>{allExcercises.filter(allEx=> allEx.id === ex.exerciseId)[0].type}</b><br/>
                                                     Exercise Value:{' '}<b>{ex.value}</b><br/>
                                                     Exercise Sort Index:{' '} <b>{ex.sortIndex}</b>
                                                     </div>
                                                     <hr />
                                                     </>
                                                 )):
                                                 <></>
                                                  }
											</div>
										</div>
								</div> 
            
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar
})

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(AddDay);
import React, { Component } from 'react';
import Piechart from '../../common/piechart';
import Columnchart from '../../common/columnchart';
import Stackedchart from '../../common/stackedchart';
import Sparklineschart from '../../common/sparklineschart';
import { Link } from 'react-router-dom';
import Donutchart from '../../common/donutchart';
import { connect } from 'react-redux';

class Dashboard extends Component {

	render() {
		const { fixNavbar } = this.props;
		return (
			<>
				<div>
					<div className={`section-body ${fixNavbar ? "marginTop" : ""} mt-3`}>
						<div className="container-fluid">
							<div className="row clearfix">
								<div className="col-lg-12">
									<div className={`section-body ${fixNavbar ? "mb-4 mt-3" : "mb-4"}`}>
										<h4>Welcome Admin!</h4>
										{/* <small>
											Measure How Fast You’re Growing Monthly Recurring Revenue.{' '}
											<a href="fake_url">Learn More</a>
										</small> */}
									</div>
								</div>
							</div>
							<div className="row clearfix">
								<div className="col-6 col-md-4 col-xl-2">
									<div className="card">
										<div className="card-body ribbon">
											{/* <div className="ribbon-box green">5</div> */}
											<Link to="/fitt-users" className="my_sort_cut text-muted">
												<i className="icon-users" />
												<span>Users</span>
											</Link>
										</div>
									</div>
								</div>
								<div className="col-6 col-md-4 col-xl-2">
									<div className="card">
										<div className="card-body">
											<Link to="/program-basic" className="my_sort_cut text-muted">
												<i className="icon-like" />
												<span>Programs</span>
											</Link>
										</div>
									</div>
								</div>
								<div className="col-6 col-md-4 col-xl-2">
									<div className="card">
										<div className="card-body ribbon">
											{/* <div className="ribbon-box orange">8</div> */}
											<Link to="/exercise-categories" className="my_sort_cut text-muted">
												<i className="icon-calendar" />
												<span>Exercises</span>
											</Link>
										</div>
									</div>
								</div>
								{/* <div className="col-6 col-md-4 col-xl-2">
									<div className="card">
										<div className="card-body">
											<Link to="/hr-payroll" className="my_sort_cut text-muted">
												<i className="icon-credit-card" />
												<span>Transactions</span>
											</Link>
										</div>
									</div>
								</div>
								<div className="col-6 col-md-4 col-xl-2">
									<div className="card">
										<div className="card-body">
											<Link to="/hr-accounts" className="my_sort_cut text-muted">
												<i className="icon-calculator" />
												<span>Accounts</span>
											</Link>
										</div>
									</div>
								</div> */}
								{/* <div className="col-6 col-md-4 col-xl-2">
									<div className="card">
										<div className="card-body">
											<Link to="/hr-report" className="my_sort_cut text-muted">
												<i className="icon-pie-chart" />
												<span>Report</span>
											</Link>
										</div>
									</div>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
const mapStateToProps = state => ({
	fixNavbar: state.settings.isFixNavbar
})

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
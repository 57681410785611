class Auth{
    constructor(){
        if(localStorage.getItem('jwttoken')!==null && localStorage.getItem('jwttoken')!==undefined &&
        localStorage.getItem('jwttoken')!==''){
            this.authenticated = true
        }
        else{
            this.authenticated = false
        }
        
    }

    login(cb){
        this.authenticated = true
        cb()
    }

    logout(cb){
        this.authenticated = false
        cb()
    }

    isAuthneticated(){
        return this.authenticated;
    }
}
export default new Auth()
import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
    ref,
    uploadBytes,
    getDownloadURL,deleteObject ,
    listAll,
    list,
    uploadBytesResumable,
  } from "firebase/storage";
  import { collection, addDoc, query, where, getDocs, doc, deleteDoc,setDoc, getDoc } from "firebase/firestore"; 
  import { storage, db } from "../../../firebases";
  import { v4 } from "uuid";
import { Link } from 'react-router-dom';
import { AtomLoader, BarsLoader2 } from 'react-loaders-kit';

class EditProgram extends Component {
    state={
        showModal:false,
        showLoader:false,
        image:null,
        imageUrl: null,
        programName: null,
        programDesc:null,
        programIsActive: true,
        programDesc: true,
        days: 1, intensity:0,
        recommendedFor: [],
        recommendedValue: 'Male',
        smallDesc:null,
        isLoading: false,
    }
    
    // uploadMedia = async(media, path) => {
    //     var mediaUrl = '';
    //     if (media == null || media == undefined){
    //         mediaUrl = ''
    //         return mediaUrl
    //     }
    //     const imageRef = ref(storage, path);
    //     await uploadBytes(imageRef, media).then(async (snapshot) => {
    //         await getDownloadURL(snapshot.ref).then((url) => {
    //         mediaUrl =  url  
    //         console.log(url)
    //         });
    //     }).catch(e=>{
    //         alert('Upload Failed, Please Try again and check your internet connection')
    //         console.log(e);     
    //     });
        
    //    return mediaUrl;
    // }
    uploadMedia = async (media, path, docId, type) => {
        var mediaUrl = '';
        if (media == null || media == undefined){
            mediaUrl = ''
            return mediaUrl
        }
        let fileRef = ref(storage, path)
        const uploadTask = uploadBytesResumable(fileRef, media)
        uploadTask.on('state_changed', null,
                (error) => {
                  alert(error);
                },
                async () => {
                  await getDownloadURL(uploadTask.snapshot.ref)
                  .then(async (url) => {
                      let data;
                      if (type === 'image'){
                          data={
                              image: url
                          }
                      }
                      if (type === 'video'){
                        data={
                            video: url
                        }
                    }
                    if (type === 'gif'){
                        data={
                            animationGif: url
                        }
                    }
                      mediaUrl = url
                      await setDoc(doc(db, "programs", docId), data, { merge: true});
                      this.getProgram(docId)
                  });
                }
              )
       return mediaUrl;
    }
    uploadToFirestore = async(days, description,
        docId, name, intensity, isActive, isPopular, imageUrl, recommendFor, smallDesc
        ) => {
            const programRef = doc(db, 'programs', docId);
            const recommend = recommendFor === 'Both'? ['Male', 'Female'] : [recommendFor]
            await setDoc(programRef,
                {
                    days: Number(days),
                    description: description,
                    id: docId,
                    name: name,
                    intensity: Number(intensity),
                    isPopular: Boolean(isPopular),
                    isActive: Boolean(isActive),
                    smallDesc: smallDesc,
                    image: imageUrl,
                    recommendedFor: recommend
                                  }
                , { merge: true })
              .then((doc) => {
                alert('Record uploaded')
              })
              .catch((error) => {
                console.log('error while editing program in firestore function', error);
                alert('Error while uploading record')
              });
        }
    deleteFileUsingUrl = async (url, type)=>{
        this.setState({
            showLoader: true
        })
        // Create a reference to the file to delete
        const desertRef = ref(storage, url);
        var { id } = this.props.match.params;
            deleteObject(desertRef).then(async () => {
              // File deleted successfully
              const programRef = doc(db, 'programs', id);
              let field;
              if(type == 'image'){
                  field = {
                      image: ''
                  }
              }
           await setDoc(programRef,
             field
            , { merge: true })
            this.getProgram(id)  
            })
            .catch((error) => {
            console.log(error);
              alert('error in deleting image');
            }); 
             
           this.setState({
            showLoader: false
        })    
    }
  
     uploadData = async (imageName) => {
          const { days, programDesc,
            programName,intensity, programIsActive, programIsPopular, recommendedValue, image,imageUrl, smallDesc} = this.state;            this.setState({
                showLoader: true
            })
        const { id } = this.props.match.params;
        let imageUrll;
        const nameOfImage = v4() + imageName
        if(imageUrl !==null && imageUrl !==undefined && imageUrl !=='' ){
            imageUrll = imageUrl; 
        }
        else{
            imageUrll = await this.uploadMedia(imageName, `programs/images/${nameOfImage}`, id, 'image')
        }
        
        await this.uploadToFirestore(days, programDesc, 
        id, programName , intensity, programIsActive, programIsPopular, imageUrll, recommendedValue, smallDesc)
        this.getProgram(id)
        // video upload code 
      };
      getProgram = async(docId) => {
        const docRef = doc(db, "programs", docId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            const data = docSnap.data()
            console.log('recommended for', data.recommendedFor);
            const recommended =data.recommendedFor.length ===2 ? 'Both': (data.recommendedFor.find(elem=> elem === 'Female')? 'Female': 'Male')
            console.log('rrrrr', recommended);
            this.setState({
                imageUrl:data.image,
                programName: data.name,
                programDesc:data.description,
                programIsActive: data.isActive,
                programIsPopular: data.isPopular,
                recommendedFor: data.recommendedFor,
                days: data.days,
                intensity: data.intensity,
                smallDesc: data.smallDesc,
                recommendedValue: recommended

            })
          } else {
            // doc.data() will be undefined in this case
            alert("No record found for this document");
            return
          }
    }
    
    componentDidMount() {
        const { id } = this.props.match.params;
        this.getProgram(id)
	  }
    
    render() {
        const { fixNavbar } = this.props;
        const { id, days: daysInProgram } = this.props.match.params;
        const { image, programDesc, programName,days, showLoader, imageUrl } = this.state
        return (
            <>
            <div>
        
									<div className="card">
                                        <br />
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                         <Link to={`/add/routine/${id}/${daysInProgram}`} >
                                         <button  style={{ backgroundColor: '#Dac27c'}} type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal"><i className="fe fe-plus mr-2" />Add Routines</button></Link>
                                        </div>
										<div className="card-body">
											<div className="row clearfix">
                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                <label style={{color: '#Dac27c'}}><b>Program Name</b></label>
													<div className="form-group">
                                                    <input type="text"
                                                value={this.state.programName}
                                                onChange={(event) => {
                                                this.setState({
                                                    programName: event.target.value
                                                })
                                                }}
                                                 className="form-control" placeholder="Program Name" name="img" /> 
													</div>
												</div>
												<div className="col-lg-12 col-md-12 col-sm-12">
                                                <label style={{color: '#Dac27c'}} ><b>Description</b></label>
													<div className="form-group">
                                                    <textarea
                                            value={this.state.programDesc}
                                            onChange={(event) => {
                                                this.setState({
                                                    programDesc:event.target.value
                                                })
                                                }} 
                                            className="form-control" placeholder="Program Description" 
                                            id="w3review" name="w3review" rows="4" cols="50"></textarea>
													</div>
												</div>
												<div className="col-md-6 col-sm-6">
													<div className="form-group">
                                                    <label style={{color: '#Dac27c'}}><b>Active</b></label>  
                                                    <select
                                            value={this.state.programIsActive}
                                            onChange={(event) => {
                                                this.setState({
                                                    programIsActive:event.target.value
                                                })
                                                }}
                                             className="form-control show-tick">
															
															<option value={true}>Active</option>
															<option value={false}>Not Active</option>
														</select>    
													</div>
												</div>
                                                <div className="col-md-6 col-sm-6">
													<div className="form-group">
                                                    <label style={{color: '#Dac27c'}}><b>Popular/ Not Popular</b></label>  
                                                    <select
                                            value={this.state.programIsPopular}
                                            onChange={(event) => {
                                                this.setState({
                                                    programIsPopular:event.target.value
                                                })
                                                }}
                                             className="form-control show-tick">
															
															<option value={true}>Popular</option>
															<option value={false}>Not Popular</option>
														</select>    
													</div>
												</div>
                                                <div className="col-md-6 col-sm-6">
													<div className="form-group">
                                                    <label style={{color: '#Dac27c'}}><b>Days</b></label>  
                                                    <input type="number"
                                                value={this.state.days}
                                                onChange={(event) => {
                                                this.setState({
                                                    days: event.target.value
                                                })
                                                }}
                                                 className="form-control" placeholder="Days" name="img" />    
													</div>
												</div>
                                                <div className="col-md-6 col-sm-6">
													<div className="form-group">
                                                    <label style={{color: '#Dac27c'}}><b>Intensity Level</b></label>  
                                                    <input type="number"
                                                value={this.state.intensity}
                                                onChange={(event) => {
                                                this.setState({
                                                    intensity: event.target.value
                                                })
                                                }}
                                                 className="form-control" placeholder="Intensity" name="img" />    
													</div>
												</div>
                                                <div className="col-md-6 col-sm-6">
													<div className="form-group">
                                                    <label style={{color: '#Dac27c'}}><b>Small Description</b></label>  
                                                    <input type="text"
                                                value={this.state.smallDesc}
                                                onChange={(event) => {
                                                this.setState({
                                                    smallDesc: event.target.value
                                                })
                                                }}
                                                 className="form-control" placeholder="Small Description" name="img" />    
													</div>
												</div>
                                                <div className="col-md-12 col-sm-12">
													<div className="form-group">
                                                    <label style={{color: '#Dac27c'}}><b>Recommended For</b></label>  
                                                    <select
                                            value={this.state.recommendedValue}
                                            onChange={(event) => {
                                                this.setState({
                                                    recommendedValue:event.target.value
                                                })
                                                }}
                                             className="form-control show-tick">
															
															<option value='Male'>Male</option>
															<option value='Female'>Female</option>
                                                            <option value='Both'>Both</option>
														</select>    
													</div>
												</div>
												<div className="col-md-12 col-sm-12">
                                                <label style={{color: '#Dac27c'}}>Upload Image</label>
													<div className="form-group">
                                                    <input type="file"
                                                accept="image/*"
                                                disabled={imageUrl !== '' && imageUrl !== undefined && imageUrl !== null ?true:false} 
                                                onChange={(event) => {
                                                this.setState({
                                                    image:event.target.files[0]
                                                })
                                                }}
                                                 className="form-control" placeholder="Upload Image" name="img" />
													</div>
												</div>
                                                <b style={{color: '#Dac27c'}}>Note: Please Delete existing media i.e Image if you want to update media. </b>
                                                <br /><br />
                                                <div className="col-md-11 col-sm-11">
                                                <b style={{color: '#Dac27c'}}>Image Url: &nbsp;&nbsp;</b> {imageUrl !==''?<><a href={imageUrl}>
                                                {imageUrl}
                                                </a>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <button
                                                onClick= {()=>this.deleteFileUsingUrl(imageUrl, 'image')}
                                                    type="button"
                                                    className="btn btn-icon js-sweetalert"
                                                    title="Delete"
                                                    data-type="confirm"
                                                >
                                                    <i className="fa fa-trash-o text-danger" />
                                                </button>
                                                </>
                                                : 'None'}
                                                </div>
                                                <br />
												<div className="col-lg-12 col-md-12 col-sm-12">
													<button
                                                    style={{ backgroundColor: '#Dac27c'}}
                                                    onClick={()=>this.uploadData(image)}
                                                     type="button" className="btn btn-primary">
														Update
													</button>
												</div>	
												</div>
											</div>
										</div>
								</div>
            
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar
})

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(EditProgram);
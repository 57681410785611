import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
    ref,
    uploadBytes, uploadString,
    getDownloadURL,deleteObject ,
    listAll,
    list,
    uploadBytesResumable,
  } from "firebase/storage";
  import { collection, addDoc, query, where, getDocs, doc, deleteDoc,setDoc, getDoc, Timestamp } from "firebase/firestore"; 
  import { storage, db } from "../../../firebases";
  import { v4 } from "uuid";
import { Link } from 'react-router-dom';
import { MDBSpinner } from 'mdb-react-ui-kit';

class Supplements extends Component {
    state={
        showModal:false,
        image:null,
        image1: null,
        recipeDescription:'',
        video: null,
        gif: null,
        name:'',
        month: new Date(),
        isLoading: true,
        color: '#Dac27c',
        isActive:true,
        url:'',
        allSupplements: []
    }
    deleteSupplements = async(docId) => {
        try{
            const docRef = doc(db, "supplements", docId);
        const docSnap = await getDoc(docRef);
        let imageRef = null
        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            const data = docSnap.data()
            imageRef = data.image
          } else {
            // doc.data() will be undefined in this case
            alert("No such document!");
            return
          }
          if(imageRef !== '' && imageRef.trim() !== '' && imageRef !== null && imageRef !== null ){
            this.deleteFileUsingUrl(imageRef)            
          }
		await deleteDoc(doc(db, "supplements", docId));
		this.getSupplements()
        }
        catch(e){
            console.log('error in deleting program',e );
            alert('Deletion Failed.')
        }

        
        
    }

    uploadMedia = async (media, path, docId, type) => {
        var mediaUrl = '';
        if (media == null || media == undefined){
            mediaUrl = ''
            return mediaUrl
        }
        let fileRef = ref(storage, path)
        const uploadTask = uploadBytesResumable(fileRef, media)
        uploadTask.on('state_changed', null,
                (error) => {
                  alert(error);
                },
                async () => {
                  await getDownloadURL(uploadTask.snapshot.ref)
                  .then(async (url) => {
                      let data;
                      if (type === 'image'){
                          data={
                              image: url
                          }
                      }
                      if (type === 'video'){
                        data={
                            video: url
                        }
                    }
                    if (type === 'gif'){
                        data={
                            animationGif: url
                        }
                    }
                      mediaUrl = url
                      await setDoc(doc(db, "supplements", docId), data, { merge: true});
                      alert('Image Uploaded')
                      this.getSupplements()
                  });
                }
              )
       return mediaUrl;
    }
    uploadToFirestore = async(url, isActive,
    docId, imageUrl
    ) => {
        const excerciseRef = doc(db, 'supplements', docId);
        await setDoc(excerciseRef,
            {
                url: url,
                isActive: Boolean(isActive),
                id: docId,
                image: imageUrl,
              }
            , { merge: true })
          .then((doc) => {
            console.log('dddd', doc);
            this.setState({
                isLoading: false
            })
            alert('Supplement uploaded')
          })
          .catch((error) => {
            console.log(error);
            alert('Error while uploading recipe')
          });
    }
    deleteFileUsingUrl = (url)=>{
        var fileUrl = url
        
        // Create a reference to the file to delete
        const desertRef = ref(storage, fileUrl);
            deleteObject(desertRef).then(() => {
              // File deleted successfully
            }).catch((error) => {
            console.log(error);
              console.log('error in deleting video');
            });        
    }
  
     uploadData = async (imageName, videoName, gifName) => {
        this.setState({
            isLoading: true
        })
         const { url,
            isActive,image} = this.state;
        let imageUrl;
        const nameOfImage = v4() + imageName?.name
        const docId = v4() + v4()
        // image upload code
        imageUrl = await this.uploadMedia(imageName, `supplements/images/${nameOfImage}`, docId, 'image')
        await this.uploadToFirestore(url, isActive,
        docId, imageUrl
        )
        this.setState({
        url: '',
        isActive:true
        })
        
        this.getSupplements()
        // video upload code 
      };
      deleteExcercise = async(docId) => {
        this.setState({
            isLoading: true
        })
        const docRef = doc(db, "exercises", docId);
        const docSnap = await getDoc(docRef);
        let videoRef = null
        let imageRef = null
        let gifRef = null
        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            const data = docSnap.data()
            videoRef = data.videoName
            imageRef = data.imageName
            gifRef = data.gifName
          } else {
            this.setState({
                isLoading: false
            })
            // doc.data() will be undefined in this case
            alert("No such document!");
            return
          }
          if(videoRef !== '' || videoRef !== null || videoRef !== null ){
            const desertRef = ref(storage, `excercises/videos/${videoRef}`);
            deleteObject(desertRef).then(() => {
              // File deleted successfully
            }).catch((error) => {
                this.setState({
                    isLoading: false
                })
              console.log('error in deleting video');
            });            
          }
          if(imageRef !== '' || imageRef !== null || imageRef !== null ){
            const desertRef = ref(storage, `excercises/images/${imageRef}`);
            deleteObject(desertRef).then(() => {
              // File deleted successfully
            }).catch((error) => {
                this.setState({
                    isLoading: false
                })
              console.log('error in deleting images');
            });            
          }
          if(gifRef !== '' || gifRef !== null || gifRef !== null ){
            const desertRef = ref(storage, `excercises/gif/${gifRef}`);
            deleteObject(desertRef).then(() => {
              // File deleted successfully
            }).catch((error) => {
                this.setState({
                    isLoading: false
                })
              console.log('error in deleting gif');
            });            
          }
		await deleteDoc(doc(db, "exercises", docId));
		this.getSupplements()
    }
     convert= (str) => {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
      }
    getDate = (timestamp) => {
        // var timestamp = 1607110465663
       console.log('tttt', timestamp);
        if(!timestamp){
           return ''
        }
        var date = new Date(timestamp.seconds*1000);
        console.log(date.getTime())
        console.log(date)
        return this.convert(date.toString())
    }
      getSupplements = async() => {
		this.setState({
			isLoading:true
		})
	    let supplementList = [] 
		const q = query(collection(db, "supplements"));
		const querySnapshot = await getDocs(q);
		console.log(querySnapshot.docs);
		querySnapshot.forEach((doc) => {
		// doc.data() is never undefined for query doc snapshots
        supplementList.push(doc.data())
		console.log(doc.id, " => ", doc.data());
		});
		this.setState({
			allSupplements: supplementList,
			isLoading: false
		})
	}
    componentDidMount() {
		this.getSupplements()
	  }
    
      updateSupplementActiveStatus = async (docId, status) => {
		const programRef = doc(db, 'supplements', docId);
        let data;
        data = { isActive: !status }

		await setDoc(programRef, data , { merge: true });
		this.getSupplements()
	}
    
    render() {
        const { fixNavbar } = this.props;
        const { image,allSupplements} = this.state
        return (
            <>
                <div>
                    <div>
                        <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between align-items-center">
                                    <ul className="nav nav-tabs page-header-tab">
                                        <li className="nav-item"><a style={{color: '#Dac27c'}}className="nav-link active" id="Departments-tab" data-toggle="tab" href="#Departments-list">Supplements</a></li>
                                        {/* <li className="nav-item"><a className="nav-link" id="Departments-tab" data-toggle="tab" href="#Departments-grid">Grid View</a></li> */}
                                    </ul>
                                    <div className="header-action">
                                        <button style={{backgroundColor: '#Dac27c'}}type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal"><i className="fe fe-plus mr-2" />Add</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-body mt-3">
                            <div className="container-fluid">
                                <div className="tab-content mt-3">
                                    <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 style={{color:'#Dac27c'}}className="card-title"><b>Supplements List</b></h3>
                                                {/*  */}
                                            </div>
                                            <div className="card-body">
                                                <div className="table-responsive">
                                                <table className="table table-striped table-hover table-vcenter mb-0">
													<thead>
														<tr>
														<th colSpan={1}className="w60">#</th>
															<th  style={{color: '#DAC27C'}} colSpan={4} className="w60">Image</th>
															<th  style={{color: '#DAC27C'}} colSpan={3}>url</th>
                                                            <th  style={{color: '#DAC27C'}} colSpan={2}>Active/ Deactive</th>
															<th  style={{color: '#DAC27C'}} colSpan={1} className='w100'>Action</th>
														</tr>
													</thead>
													<tbody>
														{
															allSupplements.length > 0 ? (
																allSupplements.map((supplement, index)=>(
													<tr key={supplement.id}>
														    <td colSpan={1}>
																{index+1}
															</td>
															<td colSpan={4}>
                                                                
                                                                <a href={supplement.url} style={{color: '#DAC27C'}} className="mb-0"><img 
                                                                style={{height:'80px', width:'80px'}}
                                                                src={supplement.image}></img></a>
																
															</td>
                                                            <td colSpan={3}>
                                                            {supplement.url}
                                                            </td>
															<td colSpan={2}>
                                                            <button 
                                                            onClick={()=>this.updateSupplementActiveStatus(supplement.id, supplement.isActive, 'active')}
																 className={supplement.isActive? 'btn-success':'btn-danger'}>
                                                                   {supplement.isActive? 'Active' : 'Deactive'}
                                                            </button>
                                                            </td>
                                                            
															<td colSpan={2}>
																
																<button
																    onClick= {()=> this.deleteSupplements(supplement.id)}
																	type="button"
																	className="btn btn-icon js-sweetalert"
																	title="Delete"
																	data-type="confirm"
																>
																	<i className="fa fa-trash-o text-danger" />
																</button>
															</td>
														</tr>
																))
															) :
															<h3>No supplements available right now</h3>
														}
													</tbody>
												</table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="Departments-grid" role="tabpanel">
                                        <div className="row clearfix">
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar1.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">John Smith</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">105</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$3100</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar2.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">Maryam Amiri</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">105</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$3100</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar3.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">Fidel Tonn</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">12</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$1800</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar4.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">Frank Camly</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">105</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$3100</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar2.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">Maryam Amiri</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">105</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$3100</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar1.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">John Smith</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">55</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$12,024</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar2.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">Maryam Amiri</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">23</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$5210</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* Modal */}
                    <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 style={{color: '#Dac27c'}} className="modal-title" id="exampleModalLabel">Add Supplement</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row clearfix">
                                        <div className="col-md-12">
                                            <label style={{color: '#Dac27c'}}><b>Url</b></label>
                                            <div className="form-group">
                                            <input type="text"
                                                value={this.state.url}
                                                onChange={(event) => {
                                                this.setState({
                                                    url: event.target.value
                                                })
                                                }}
                                                 className="form-control" placeholder="Url" name="img" />                                            </div>
                                        </div>
                                        <div className="col-md-12 col-sm-12">
													<div className="form-group">
														<select 
                                                        value={this.state.isActive}
                                                        onChange={(e)=>{
                                                            this.setState({
                                                                isActive:e.target.value
                                                            })
                                                        }}
                                                        className="form-control show-tick">
															<option value={true}>Active</option>
															<option value={false}>Deactive</option>
														</select>
													</div>
                                        </div>
                                        <div className="col-md-12">
                                            <label>Upload Image</label>
                                            <div className="form-group">
                                                <input type="file"
                                                accept="image/*"
                                            
                                                onChange={(event) => {
                                                this.setState({
                                                    image:event.target.files[0]
                                                })
                                                }}
                                                 className="form-control" placeholder="Upload Image" name="img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button type="button"
                                    onClick={()=>this.uploadData(image)}
                                     className="btn btn-primary">Save changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar
})

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(Supplements);
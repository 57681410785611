import React, { Component } from 'react';
import { connect } from 'react-redux';
import { doc, getDoc, setDoc, collection, query, where, getDocs, deleteDoc } from "firebase/firestore";
import  { db }  from '../../../firebases';
import ReactPaginate from "react-paginate";
// import BarWave from "react-cssfx-loading/lib/BarWave";




class Users extends Component {
    state = {
		allUsers: [],
		isLoading: false,
		startEditing: false,
		name:'',
		email: '',
		age: null,
		gender:'',
		heightFt: null,
		heightInches: null,
		phone: '',
		weight: null,
		page:0,
		employeesPerPage:10
	  }
	  kgToPoundConverter=(kg)=>{
        if(kg===undefined|| kg===null){
			return 0
		}
		return (kg*2.20).toFixed(2)
	  }
	  displayUsers =()=>{ 
	const {page, employeesPerPage, allUsers} = this.state
	const numberOfRecordsVistited = page * employeesPerPage;
	return allUsers
  .slice(
    numberOfRecordsVistited,
    numberOfRecordsVistited + employeesPerPage
  )
  .map((user, index) => {
    return (
      <tr key={user.id}>
														    <td>
																{user.id}
															</td>
															<td>
																<h6 style={{color: '#Dac27c'}}className="mb-0">{user.name}</h6>
																<span>{user.email}</span>
															</td>
															<td>{user.bioDetails.gender}</td>
															<td>{user.phone}</td>
															<td>{user.bioDetails.age}</td>
															<td>{this.kgToPoundConverter(user.bioDetails.weight)} Lbs </td>
															<td>{user.bioDetails.height.feet} ft {user.bioDetails.height.inches} inches</td>
															<td>
																<button 
																onClick={()=>this.updateUserActiveStatus(user.id, user.isActive)}
																 className={user.isActive? 'btn-success':'btn-danger'}>
                                                                   {user.isActive? 'Active' : 'Blocked'}
																</button>
															</td>
															{/* <td> */}
																{/* <button
																	type="button"
																	className="btn btn-icon"
																	title="Edit"
																>
																	<i className="fa fa-edit" />
																</button> */}
																{/* <button
																    onClick= {()=> this.deleteUser(user.id)}
																	type="button"
																	className="btn btn-icon js-sweetalert"
																	title="Delete"
																	data-type="confirm"
																>
																	<i className="fa fa-trash-o text-danger" />
																</button>
															</td> */}
														</tr>
    );

  });
}

 changePage = ({ selected }) => {
	this.setState({page:selected});
  };
	  componentDidMount() {
		// this.checkLoginStatusAfterEveryOneSeconds()
		this.getUsers()
		this.displayUsers()
	  }
	   getUsers = async() => {
		this.setState({
			isLoading:true
		})
	    let userList = []
		const q = query(collection(db, "users"));
		const querySnapshot = await getDocs(q);
		console.log(querySnapshot.docs);
		querySnapshot.forEach((doc) => {
		// doc.data() is never undefined for query doc snapshots
        userList.push(doc.data())
		console.log(doc.id, " => ", doc.data());
		});
		this.setState({
			allUsers: userList,
			isLoading: false
		})
	}
	setUserState = ()=>{
		this.setState({

		})
	}
	
	updateUserActiveStatus = async(docId, activeStatus) => {
		const userRef = doc(db, 'users', docId);
		await setDoc(userRef, { isActive: !activeStatus }, { merge: true });
		this.getUsers()
	}
	deleteUser = async(docId) => {
		await deleteDoc(doc(db, "users", docId));
		this.getUsers()
    }

	render() {
		const { fixNavbar } = this.props;
		const { allUsers, startEditing } = this.state
		return (
			<>
				<div>
					<div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
						<div className="container-fluid">
						</div>
					</div>
					
					{ startEditing? (
						<div>
									<div className="card">
										<div className="card-body">
											<div className="row clearfix">
												<div className="col-lg-12 col-md-12 col-sm-12">
													<div className="form-group">
														<input
															type="text"
															className="form-control"
															placeholder="Name"
														/>
													</div>
												</div>
												<div className="col-lg-6 col-md-6 col-sm-12">
													<div className="form-group">
														<input
															type="text"
															className="form-control"
															placeholder="Email"
														/>
													</div>
												</div>
												<div className="col-lg-6 col-md-6 col-sm-12">
													<div className="form-group">
														<input
															type="text"
															className="form-control"
															placeholder="Phone"
														/>
													</div>
												</div>
												<div className="col-md-4 col-sm-12">
													<div className="form-group">
														<input
															type="text"
															className="form-control"
															placeholder="Age"
														/>
													</div>
												</div>
												<div className="col-md-4 col-sm-12">
													<div className="form-group">
														<input
															type="text"
															className="form-control"
															placeholder="Weight"
														/>
													</div>
												</div>
												<div className="col-md-4 col-sm-12">
													<div className="form-group">
														<input
															type="text"
															className="form-control"
															placeholder="Height"
														/>
													</div>
												</div>
												<div className="col-md-4 col-sm-12">
													<div className="form-group">
														<select className="form-control show-tick">
															<option>Select Status Type</option>
															<option>Active</option>
															<option>Deactive</option>
														</select>
													</div>
												</div>
												<div className="col-lg-12 col-md-12 col-sm-12">
													<button type="button" className="btn btn-primary">
														Add
													</button>
													{'                                  '}
													<button
													    onClick={()=>{
															this.setState({
																startEditing:false
															})
														}}
														type="button"
														className="btn btn-secondary"
														data-dismiss="modal"
													>
														CLOSE
													</button>
												</div>	
												</div>
											</div>
										</div>
								</div>
					):
					(<div className="section-body mt-3">
						<div className="container-fluid">
							<div className="tab-content mt-3">
								<div className="tab-pane fade show active" id="user-list" role="tabpanel">
									<div className="card">
										<div className="card-header">
											<h3 className="card-title">User List</h3>
											{/* <div className="card-options">
												<form>
													<div className="input-group">
														<input
															type="text"
															className="form-control form-control-sm"
															placeholder="Search something..."
															name="s"
														/>
														<span className="input-group-btn ml-2">
															<button className="btn btn-sm btn-default" type="submit">
																<span className="fe fe-search" />
															</button>
														</span>
													</div>
												</form>
											</div> */}
										</div>
										<div className="card-body">
											<div className="table-responsive">
												<table className="table table-striped table-hover table-vcenter text-nowrap mb-0">
													<thead>
														<tr>
														<th className="w60">User Id</th>
															<th style={{color: '#Dac27c'}} className="w60">Name</th>
															<th style={{color: '#Dac27c'}}>Gender</th>
															<th style={{color: '#Dac27c'}}>Phone</th>
															<th style={{color: '#Dac27c'}}>Age</th>
															<th style={{color: '#Dac27c'}}>Weight</th>
															<th style={{color: '#Dac27c'}}>Height</th>
															<th style={{color: '#Dac27c'}}>Active</th>
															{/* <th style={{color: '#Dac27c'}} className='w100'>Action</th> */}
														</tr>
													</thead>
													<tbody>
														{
															allUsers.length > 0 ? (
																<>
																{this.displayUsers()}
																<ReactPaginate
																previousLabel={"Previous"}
																nextLabel={"Next"}
																pageCount={Math.ceil(allUsers.length / this.state.employeesPerPage)}
																onPageChange={(selected)=>this.changePage(selected)}
																containerClassName={"navigationButtons"}
																previousLinkClassName={"previousButton"}
																nextLinkClassName={"nextButton"}
																disabledClassName={"navigationDisabled"}
																activeClassName={"navigationActive"}
																/>
                                                                </>
															) :
															<h3>No users available right now</h3>
														}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					)}

				</div>
			</>
		);
	}
}
const mapStateToProps = state => ({
	fixNavbar: state.settings.isFixNavbar
})

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(Users);
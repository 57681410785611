import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { collection, addDoc } from "firebase/firestore"; 
import  { db }  from '../../firebases';
import { isDataOk } from '../../utils';
import ReactLoading from 'react-loading';
import logo1 from '../../assets/images/log2-bg.png'
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { loginAction } from '../../actions/authAction';
import { connect } from 'react-redux';
import auth from '../../auth';


class SignUp extends Component {
	constructor(props) {
        super(props);
        
    }
    setLoginState() {
        this.props.loginAction()
    }
	state = {
		name: '',
		email: '',
		password: '',
		isLoading: false
	  }
	addUser = ()=>{
		const { name, email, password } = this.state;
		const authh = getAuth();
		signInWithEmailAndPassword(authh, email, password)
		.then(async (userCredential) => {
			// Signed in 
			const user = userCredential.user;
			// alert('User Created Successfully')
			await localStorage.setItem('jwttoken', user.uid)
			auth.login(()=>{
             this.props.history.push('/')
			})
			// window.location.href = '/'
			
			// console.log('userrrr', user);
			// this.setLoginState()
			// window.location.href = '/'
			// ...
		})
		.catch((error) => {
			const errorCode = error.code;
			const errorMessage = error.message;
			alert(errorMessage);
		});
	}
	render() {
		const {name, email, password, isLoading} = this.state
		// const db = firebase.firestore();
		// const addUser = e => {
		// 	// e.preventDefault();
		// 	console.log(isDataOk(name), isDataOk(email), isDataOk(password));
			
		// 	if (!isDataOk(name) || !isDataOk(email) || !isDataOk(password)){
		// 		alert('name, email, password can not be empty' );
		// 		return null;
		// 	}
		// else{
		// 	this.setState({
		// 		IsLoading:true
		// 	})
		// 	addDoc(collection(db, "admin"), {
		// 		username: name,
		// 		email: email,
		// 		password: password,
		// 	  })
		// 	  .then(() => {
		// 		alert('Admin User Created 👍' );
		// 		this.setState({
		// 			name: '',
		// 			email: '',
		// 			password: '',
		// 			isLoading: false
		// 		})
		// 		this.props.history.push("/login");
		// 	  })
		// 	  .catch((error) => {
		// 		console.log(error);
				
		// 		this.setState({
		// 			name: '',
		// 			email: '',
		// 			password: '',
		// 			isLoading: false
		// 		})
		// 		alert('OOPS! Registration failed.', error.message);
		// 	  });
		// }
		//   };
		  
		return (
			isLoading?
			<ReactLoading />:
			(<div  className="auth">
				<div style={{backgroundColor: '#DAC27C'}} className="auth_left">
					<div className="card">
						<div className="card-body">
							{/* <div className="card-title">Create new account</div> */}
							{/* <div className="form-group">
								<label className="form-label">Name</label>
								<input onChange={(e)=> this.setState({
									name: e.target.value
								})}
									 type="text" className="form-control" placeholder="Enter name" />
							</div> */}
							<div className="form-group">
								<label className="form-label">Email address</label>
								<input onChange={(e)=> this.setState({
									email: e.target.value
								})} type="email" className="form-control" placeholder="Enter email" />
							</div>
							<div className="form-group">
								<label className="form-label">Password</label>
								<input onChange={(e)=> this.setState({
									password: e.target.value
								})} type="password" className="form-control" placeholder="Password" />
							</div>
							{/* <div className="form-group">
								<label className="custom-control custom-checkbox">
									<input type="checkbox" className="custom-control-input" />
									<span className="custom-control-label">
										Agree the <a href="/#">terms and policy</a>
									</span>
								</label>
							</div> */}
							<div style={{backgroundColor: '#DAC27C'}} className="form-footer">
								<Link onClick={()=>this.addUser()} style={{backgroundColor: '#DAC27C'}} className="btn btn-primary btn-block" to="#">
									Login
								</Link>
							</div>
						</div>
						{/* <div className="text-center text-muted">
							Already have account? <Link to="/login">Sign In</Link>
						</div> */}
					</div>
				</div>
				<div className="auth_right">
					<div className="carousel slide" data-ride="carousel" data-interval={3000}>
						<div className="carousel-inner">
							<div className="carousel-item active">
								<img src={logo1} className="img-fluid" alt="login page" />
								<div className="px-4 mt-4">
								<h4>FITT ELITE</h4>
								<p>Where you get fit.</p>
								</div>
							</div>
							<div className="carousel-item">
								<img src={logo1} className="img-fluid" alt="login page" />
								<div className="px-4 mt-4">
									<h4>FITT ELITE</h4>
									<p>Where you get fit.</p>
								</div>
							</div>
							<div className="carousel-item">
								<img src={logo1} className="img-fluid" alt="login page" />
								<div className="px-4 mt-4">
									<h4>FITT ELITE</h4>
									<p>Where you get fit.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>)
		);
	}
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    boxOpen: state.settings.isbox,
    box2Open: state.settings.isbox2,
    box3Open: state.settings.isbox3,
    box4Open: state.settings.isbox4,
    box5Open: state.settings.isbox5,
    box6Open: state.settings.isbox6,
})

const mapDispatchToProps = dispatch => ({
   
})
export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
    ref,
    uploadBytes, uploadString,
    getDownloadURL,deleteObject ,
    listAll,
    list,
    uploadBytesResumable,
  } from "firebase/storage";
  import { collection, addDoc, query, where, getDocs, doc, deleteDoc,setDoc, getDoc, orderBy } from "firebase/firestore"; 
  import { storage, db } from "../../../firebases";
  import { v4 } from "uuid";
import { Link } from 'react-router-dom';

class ProgramBasic extends Component {
    state={
        showModal:false,
        image:null,
        programName: '',
        programDesc:'',
        isActive: true,
        isPopular: true,
        recommendedFor: 'Male',
        smallDesc: '',
        days: 0,
        isLoading: false,
        intensityLevel: 0,
        allPrograms: []
    }
    // update status of popular and active
    updateProgramActiveStatus = async(docId, status, type) => {
		const programRef = doc(db, 'programs', docId);
        let data;
        if(type === 'active'){
           data = { isActive: !status }
        }
        else if (type === 'popular'){
            data = { isPopular: !status }
        }
		await setDoc(programRef, data , { merge: true });
		this.getPrograms()
	}
    // upload media
    // uploadMedia = async(media, path) => {
    //     var mediaUrl = '';
    //     if (media == null || media == undefined){
    //         mediaUrl = ''
    //         return mediaUrl
    //     }
    //     const imageRef = ref(storage, path);
    //     await uploadBytes(imageRef, media).then(async (snapshot) => {
    //         await getDownloadURL(snapshot.ref).then((url) => {
    //         mediaUrl =  url  
    //         console.log(url)
    //         });
    //     }).catch(e=>{
    //         alert('Upload Failed, Please Try again and check your internet connection')
    //         console.log(e);     
    //     });
        
    //    return mediaUrl;
    // }
    uploadMedia = async (media, path, docId, type) => {
        var mediaUrl = '';
        if (media == null || media == undefined){
            mediaUrl = ''
            return mediaUrl
        }
        let fileRef = ref(storage, path)
        const uploadTask = uploadBytesResumable(fileRef, media)
        uploadTask.on('state_changed', null,
                (error) => {
                  alert(error);
                },
                async () => {
                  await getDownloadURL(uploadTask.snapshot.ref)
                  .then(async (url) => {
                      let data;
                      if (type === 'image'){
                          data={
                              image: url
                          }
                      }
                      if (type === 'video'){
                        data={
                            video: url
                        }
                    }
                    if (type === 'gif'){
                        data={
                            animationGif: url
                        }
                    }
                      mediaUrl = url
                      await setDoc(doc(db, "programs", docId), data, { merge: true});
                      this.getPrograms()
                  });
                }
              )
       return mediaUrl;
    }
    uploadToFirestore = async(days, description,
    docId, name, intensity, isActive, isPopular, imageUrl, recommendFor, smallDesc
    ) => {
        const excerciseRef = doc(db, 'programs', docId);
        const recommend = recommendFor === 'Both'? ['Male', 'Female'] : [recommendFor]
        await setDoc(excerciseRef,
            {
                days: Number(days),
                description: description,
                id: docId,
                name: name,
                intensity: Number(intensity),
                isPopular: Boolean(isPopular),
                isActive: Boolean(isActive),
                smallDesc: smallDesc,
                image: imageUrl,
                recommendedFor: recommend
                              }
            , { merge: true })
          .then((doc) => {
            console.log('dddd', doc);
            alert('Record uploaded')
          })
          .catch((error) => {
            console.log(error);
            alert('Error while uploading record')
          });
    }
    deleteFileUsingUrl = (url)=>{
        var fileUrl = url
        
        // Create a reference to the file to delete
        const desertRef = ref(storage, fileUrl);
            deleteObject(desertRef).then(() => {
              // File deleted successfully
            }).catch((error) => {
            console.log(error);
              console.log('error in deleting video');
            });        
    }
  
     uploadData = async (imageName) => {
         const { days, programDesc,
            programName,intensityLevel, isActive, isPopular, recommendedFor, image, smallDesc} = this.state;
        const nameOfImage = v4() + imageName
        const docId = v4() + v4()
        // image upload code
        const imageUrl = await this.uploadMedia(imageName, `programs/images/${nameOfImage}`, docId, 'image')
        await this.uploadToFirestore(days, programDesc, docId, 
        programName, intensityLevel , isActive, isPopular, imageUrl, recommendedFor, smallDesc
        )
        this.setState({
        programName: '',
        programDesc:'',
        days: 0,
        intensityLevel: 0,
        isActive:true,isPopular:true, recommendedFor: 'Male', image: '', smallDesc: ''
        })
        
        this.getPrograms()
        // video upload code 
      };
      deletePrograms = async(docId) => {
        try{
            const docRef = doc(db, "programs", docId);
        const docSnap = await getDoc(docRef);
        let imageRef = null
        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            const data = docSnap.data()
            imageRef = data.image
          } else {
            // doc.data() will be undefined in this case
            alert("No such document!");
            return
          }
          if(imageRef !== '' || imageRef !== null || imageRef !== null ){
            // const desertRef = ref(storage, `programs/images/${imageRef}`);
            // deleteObject(desertRef).then(() => {
            //   // File deleted successfully
            // }).catch((error) => {
            //   console.log('error in deleting images');
            // });    
            this.deleteFileUsingUrl(imageRef)        
          }
		await deleteDoc(doc(db, "programs", docId));
		this.getPrograms()
        }
        catch(e){
            console.log('error in deleting program',e );
            alert('Deletion Failed.')
        }

        
        
    }
    
      getPrograms = async() => {
		this.setState({
			isLoading:true
		})
	    let programsList = []
		const q = query(collection(db, "programs"), orderBy('name'));
		const querySnapshot = await getDocs(q);
		console.log(querySnapshot.docs);
		querySnapshot.forEach((doc) => {
		// doc.data() is never undefined for query doc snapshots
        programsList.push(doc.data())
		console.log(doc.id, " => ", doc.data());
		});
		this.setState({
			allPrograms: programsList,
			isLoading: false
		})
	}
    componentDidMount() {
		this.getPrograms()
	  }
    
    render() {
        const { fixNavbar } = this.props;
        const { image, allPrograms, recommendedFor } = this.state
        return (
            <>
                <div>
                    <div>
                        <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between align-items-center">
                                    <ul className="nav nav-tabs page-header-tab">
                                        {/* <li className="nav-item"><a className="nav-link active" id="Departments-tab" data-toggle="tab" href="#Departments-list">List View</a></li>
                                        <li className="nav-item"><a className="nav-link" id="Departments-tab" data-toggle="tab" href="#Departments-grid">Grid View</a></li> */}
                                    </ul>
                                    <div className="header-action">
                                        <button style={{backgroundColor: '#DAC27C'}} type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal"><i className="fe fe-plus mr-2" />Add</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-body mt-3">
                            <div className="container-fluid">
                                <div className="tab-content mt-3">
                                    <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 style={{color: '#Dac27c'}}className="card-title"><b>Programs List</b></h3>
                                                {/* <div className="card-options">
                                                    <form>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control form-control-sm" placeholder="Search something..." name="s" />
                                                            <span className="input-group-btn ml-2"><button className="btn btn-icon" type="submit"><span className="fe fe-search" /></button></span>
                                                        </div>
                                                    </form>
                                                </div> */}
                                            </div>
                                            <div className="card-body">
                                                <div className="table-responsive">
                                                <table className="table table-striped table-hover table-vcenter mb-0">
													<thead>
														<tr>
														<th colSpan={1} className="w60">#</th>
															<th  colSpan={2} style={{color: '#Dac27c'}}>Name</th>
															<th style={{color: '#Dac27c'}} colSpan={1}>Days</th>
															<th style={{color: '#Dac27c'}} colSpan={1}>Intensity Level</th>
															<th style={{color: '#Dac27c'}} colSpan={1}>Active</th>
															<th style={{color: '#Dac27c'}} colSpan={1}>Popular</th>
															<th style={{color: '#Dac27c'}} colSpan={2}>Small Description</th>
                                                            <th style={{color: '#Dac27c'}} colSpan={1}>Recommended For</th>
															<th style={{color: '#Dac27c'}} colSpan={2} className='w100'>Action</th>
														</tr>
													</thead>
													<tbody>
														{
															allPrograms.length > 0 ? (
																allPrograms.map((program, index)=>(
													<tr key={program.id}>
														    <td colSpan={1}>
																{index+1}
															</td>
															<td colSpan={2}>
																<h6 style={{color: '#Dac27c'}} className="mb-0">{program.name}</h6>
															
															</td>
															<td colSpan={1}>{program.days}</td>
															<td colSpan={1}>{program.intensity}</td>
															<td colSpan={1}>
                                                            <button 
                                                            onClick={()=>this.updateProgramActiveStatus(program.id, program.isActive, 'active')}
																 className={program.isActive? 'btn-success':'btn-danger'}>
                                                                   {program.isActive? 'Active' : 'Deactive'}
                                                            </button>
                                                            </td>
															<td colSpan={1}>
                                                            <button 
																onClick={()=>this.updateProgramActiveStatus(program.id, program.isPopular, 'popular')}
																 className={program.isPopular? 'btn-success':'btn-danger'}>
                                                                   {program.isPopular? 'Popular' : 'Not Popular'}
                                                            </button>
                                                            </td>
															<td colSpan={2}>{program.smallDesc}</td>
                                                            <td colSpan={1}>{program.recommendedFor.join(", ")}</td>
															<td colSpan={2}>
																<button
																	type="button"
                                                            
																	className="btn btn-icon"
																	title="Edit"
																>
                                                            <Link to={`/edit/program/${program.id}/${program.days}`}>
																<i className="fa fa-edit" />
                                                            </Link>
																</button>
																<button
																    onClick= {()=> this.deletePrograms(program.id)}
																	type="button"
																	className="btn btn-icon js-sweetalert"
																	title="Delete"
																	data-type="confirm"
																>
																	<i className="fa fa-trash-o text-danger" />
																</button>
															</td>
														</tr>
																))
															) :
															<h3>No programs available right now</h3>
														}
													</tbody>
												</table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="Departments-grid" role="tabpanel">
                                        <div className="row clearfix">
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar1.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">John Smith</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">105</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$3100</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar2.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">Maryam Amiri</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">105</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$3100</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar3.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">Fidel Tonn</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">12</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$1800</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar4.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">Frank Camly</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">105</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$3100</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar2.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">Maryam Amiri</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">105</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$3100</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar1.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">John Smith</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">55</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$12,024</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar2.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">Maryam Amiri</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">23</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$5210</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* Modal */}
                    <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="row clearfix">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                            <input type="text"
                                                style={{color: '#DAC27C'}}
                                                value={this.state.programName}
                                                onChange={(event) => {
                                                this.setState({
                                                    programName: event.target.value
                                                })
                                                }}
                                                 className="form-control" placeholder="Program Name" name="program" />                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                            <textarea
                                            value={this.state.programDesc}
                                            onChange={(event) => {
                                                this.setState({
                                                    programDesc:event.target.value
                                                })
                                                }} 
                                            className="form-control" placeholder="Excercise Description" 
                                            id="w3review" name="w3review" rows="4" cols="50"></textarea>
   
                                                                                         </div>
                                        </div>
                                        <div className="col-md-12">
                                            
                                            <div className="form-group">
                                            <label><b>Days</b></label>
                                            <input type="number"
                                                value={this.state.days}
                                                onChange={(event) => {
                                                this.setState({
                                                    days: event.target.value
                                                })
                                                }}
                                                 className="form-control" placeholder="Days" name="program" />     
   
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                            <label><b>Intensity level</b></label>
                                            <input type="number"
                                                min={0} max={3}
                                                value={this.state.intensityLevel}
                                                onChange={(event) => {
                                                this.setState({
                                                    intensityLevel: event.target.value
                                                })
                                                }}
                                                 className="form-control" placeholder="Intensity Level" name="program" />     
   
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                             <label><b>Active</b></label>
                                            <select
                                            value={this.state.isActive}
                                            onChange={(event) => {
                                                this.setState({
                                                    isActive:event.target.value
                                                })
                                                }}
                                             className="form-control show-tick">
															
															<option value={true}>Active</option>
															<option value={false}>DeActive</option>
														</select>    
                                                </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                              <label><b>Popular/Not Popular</b></label>
                                            <select
                                            value={this.state.isPopular}
                                            onChange={(event) => {
                                                this.setState({
                                                    isPopular:event.target.value
                                                })
                                                }}
                                             className="form-control show-tick">
															
															<option value={true}>Popular</option>
															<option value={false}>Not Popular</option>
														</select>    
                                                </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                              <label><b>Recommended For</b></label>
                                            <select
                                            value={this.state.recommendedFor}
                                            onChange={(event) => {
                                                this.setState({
                                                    recommendedFor:event.target.value
                                                })
                                                }}
                                             className="form-control show-tick">
															
															<option value='Male'>Male</option>
															<option value='Female'>Female</option>
                                                            <option value='Both'>Both</option>
														</select>    
                                                </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                            <input type="text"
                                                value={this.state.smallDesc}
                                                onChange={(event) => {
                                                this.setState({
                                                    smallDesc: event.target.value
                                                })
                                                }}
                                                 className="form-control" placeholder="Small Description" name="program" />                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <label>Upload Image</label>
                                            <div className="form-group">
                                                <input type="file"
                                                accept="image/*"
                                                onChange={(event) => {
                                                this.setState({
                                                    image:event.target.files[0]
                                                })
                                                }}
                                                 className="form-control" placeholder="Upload Image" name="inmg" />
                                        </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button style={{backgroundColor: '#DAC27C'}} type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button type="button"
                                    style={{backgroundColor: '#DAC27C'}}
                                    onClick={()=>this.uploadData(image)}
                                     className="btn btn-primary">Save changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar
})

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(ProgramBasic);
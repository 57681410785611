import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
    ref,
    uploadBytes, uploadString,
    getDownloadURL,deleteObject ,
    listAll,
    list,
    uploadBytesResumable,
  } from "firebase/storage";
  import { collection, addDoc, query, where, getDocs, doc, deleteDoc,setDoc, getDoc, orderBy } from "firebase/firestore"; 
  import { storage, db } from "../../../firebases";
  import { v4 } from "uuid";
import { Link } from 'react-router-dom';
import { MDBSpinner } from 'mdb-react-ui-kit';

class ExcerciseCategory extends Component {
    state={
        showModal:false,
        image:null,
        image1: null,
        video: null,
        gif: null,
        excerciseName: '',
        excerciseDesc:'',
        isActive: true,
        excerciseType: 'repeats',
        isLoading: true,
        color: '#Dac27c',
        allExcercises: []
    }
    // uploadTest = async ()=>{
    //     const { image, image1 } = this.state
    //     let fileRef = ref(storage, `excercises/images/${image1.name}`)
    //     const uploadTask = uploadBytesResumable(fileRef, image1)
    //     uploadTask.on('state_changed',  async (snapshot)=>{
    //         const progress = (snapshot.bytesTransferred / snapshot.totalBytes)*100
    //         console.log(`upload is ${progress}% done`)
    //         await getDownloadURL(snapshot.ref).then((url) => {
    //             mediaUrl =  url  
    //             console.log('new method',url)
    //             });

    //     })
    // }
    uploadMedia = async (media, path, docId, type) => {
        var mediaUrl = '';
        if (media == null || media == undefined){
            mediaUrl = ''
            return mediaUrl
        }
        let fileRef = ref(storage, path)
        const uploadTask = uploadBytesResumable(fileRef, media)
        uploadTask.on('state_changed', null,
                (error) => {
                  alert(error);
                },
                async () => {
                  await getDownloadURL(uploadTask.snapshot.ref)
                  .then(async (url) => {
                      let data;
                      if (type === 'image'){
                          data={
                              image: url
                          }
                      }
                      if (type === 'video'){
                        data={
                            video: url
                        }
                    }
                    if (type === 'gif'){
                        data={
                            animationGif: url
                        }
                    }
                      mediaUrl = url
                      await setDoc(doc(db, "exercises", docId), data, { merge: true});
                  });
                }
              )
       return mediaUrl;
    }
    uploadToFirestore = async(gifUrl, description,
    docId, name, type, videoUrl, imageUrl, isActive
    ) => {
        const excerciseRef = doc(db, 'exercises', docId);
        await setDoc(excerciseRef,
            {
                animationGif: gifUrl,
                description: description,
                isActive: Boolean(isActive),
                id: docId,
                name: name,
                type: type,
                video: videoUrl,
                image: imageUrl,
              }
            , { merge: true })
          .then((doc) => {
            console.log('dddd', doc);
            this.setState({
                isLoading: false
            })
            alert('Record uploaded')
          })
          .catch((error) => {
            console.log(error);
            alert('Error while uploading record')
          });
    }
    deleteFileUsingUrl = ()=>{
        var fileUrl = 
        'https://firebasestorage.googleapis.com/v0/b/fitt-elite.appspot.com/o/excercises%2Fimages%2F9e71aec7-144f-4090-b6ac-2b9e2c8fcc3eimage%20(7).png?alt=media&token=2a5a14d2-b9f9-48be-afd7-c9eec27ac69e';
        
        // Create a reference to the file to delete
        const desertRef = ref(storage, fileUrl);
            deleteObject(desertRef).then(() => {
              // File deleted successfully
            }).catch((error) => {
            console.log(error);
              console.log('error in deleting video');
            });        
    }
  
     uploadData = async (imageName, videoName, gifName) => {
        this.setState({
            isLoading: true
        })
         const { excerciseName,
            excerciseDesc,excerciseType, isActive} = this.state;
        let imageUrl;
        let videoUrl;
        let gifUrl;
        const nameOfImage = v4() + imageName?.name
        const nameOfVideo = v4() + videoName?.name
        const nameOfGif = v4() + gifName?.name
        const docId = v4() + v4()
        // image upload code
        imageUrl = await this.uploadMedia(imageName, `excercises/images/${nameOfImage}`, docId, 'image')
        videoUrl = await this.uploadMedia(videoName, `excercises/videos/${nameOfVideo}`, docId, 'video')
        gifUrl = await this.uploadMedia(gifName, `excercises/gif/${nameOfGif}`, docId, 'gif')
        await this.uploadToFirestore(gifUrl,excerciseDesc, 
        docId, excerciseName , excerciseType, videoUrl, imageUrl, isActive
        )
        this.setState({
        excerciseName: '',
        excerciseDesc:'',
        excerciseType: 'repeats'
        })
        
        this.getExcercises()
        // video upload code 
      };
      deleteExcercise = async(docId) => {
        this.setState({
            isLoading: true
        })
        const docRef = doc(db, "exercises", docId);
        const docSnap = await getDoc(docRef);
        let videoRef = null
        let imageRef = null
        let gifRef = null
        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            const data = docSnap.data()
            videoRef = data.videoName
            imageRef = data.imageName
            gifRef = data.gifName
          } else {
            this.setState({
                isLoading: false
            })
            // doc.data() will be undefined in this case
            alert("No such document!");
            return
          }
          if(videoRef !== '' || videoRef !== null || videoRef !== null ){
            const desertRef = ref(storage, `excercises/videos/${videoRef}`);
            deleteObject(desertRef).then(() => {
              // File deleted successfully
            }).catch((error) => {
                this.setState({
                    isLoading: false
                })
              console.log('error in deleting video');
            });            
          }
          if(imageRef !== '' || imageRef !== null || imageRef !== null ){
            const desertRef = ref(storage, `excercises/images/${imageRef}`);
            deleteObject(desertRef).then(() => {
              // File deleted successfully
            }).catch((error) => {
                this.setState({
                    isLoading: false
                })
              console.log('error in deleting images');
            });            
          }
          if(gifRef !== '' || gifRef !== null || gifRef !== null ){
            const desertRef = ref(storage, `excercises/gif/${gifRef}`);
            deleteObject(desertRef).then(() => {
              // File deleted successfully
            }).catch((error) => {
                this.setState({
                    isLoading: false
                })
              console.log('error in deleting gif');
            });            
          }
		await deleteDoc(doc(db, "exercises", docId));
		this.getExcercises()
    }
    
      getExcercises = async() => {
		this.setState({
			isLoading:true
		})
	    let excerciseList = []
		const q = query(collection(db, "exercises"), orderBy("name"));
		const querySnapshot = await getDocs(q);
		console.log(querySnapshot.docs);
		querySnapshot.forEach((doc) => {
		// doc.data() is never undefined for query doc snapshots
        excerciseList.push(doc.data())
		console.log(doc.id, " => ", doc.data());
		});
		this.setState({
			allExcercises: excerciseList,
			isLoading: false
		})
	}
    componentDidMount() {
		this.getExcercises()
	  }
    
      updateExerciseActiveStatus = async (docId, status) => {
		const programRef = doc(db, 'exercises', docId);
        let data;
        data = { isActive: !status }

		await setDoc(programRef, data , { merge: true });
		this.getExcercises()
	}
    
    render() {
        const { fixNavbar } = this.props;
        const { image, video, gif, allExcercises, showModal, isLoading } = this.state
        return (
            <>
                <div>
                    <div>
                        <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between align-items-center">
                                    <ul className="nav nav-tabs page-header-tab">
                                        <li className="nav-item"><a style={{color: '#Dac27c'}}className="nav-link active" id="Departments-tab" data-toggle="tab" href="#Departments-list">Exercises</a></li>
                                        {/* <li className="nav-item"><a className="nav-link" id="Departments-tab" data-toggle="tab" href="#Departments-grid">Grid View</a></li> */}
                                    </ul>
                                    <div className="header-action">
                                        <button style={{backgroundColor: '#Dac27c'}}type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal"><i className="fe fe-plus mr-2" />Add</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-body mt-3">
                            <div className="container-fluid">
                                <div className="tab-content mt-3">
                                    <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 style={{color:'#Dac27c'}}className="card-title"><b>Exercise List</b></h3>
                                                {/* <div className="card-options">
                                                    <form>
                                                        <div className="input-group">
                                                            <input type="text" className="form-control form-control-sm" placeholder="Search something..." name="s" />
                                                            <span className="input-group-btn ml-2"><button className="btn btn-icon" type="submit"><span className="fe fe-search" /></button></span>
                                                        </div>
                                                    </form>
                                                </div> */}
                                            </div>
                                            <div className="card-body">
                                                <div className="table-responsive">
                                                <table className="table table-striped table-hover table-vcenter mb-0">
													<thead>
														<tr>
														<th colSpan={1}className="w60">#</th>
															<th  style={{color: '#DAC27C'}} colSpan={5} className="w60">Name</th>
															<th  style={{color: '#DAC27C'}} colSpan={2}>Type</th>
                                                            <th  style={{color: '#DAC27C'}} colSpan={2}>Active</th>
															<th  style={{color: '#DAC27C'}} colSpan={2} className='w100'>Action</th>
														</tr>
													</thead>
													<tbody>
														{
															allExcercises.length > 0 ? (
																allExcercises.map((excercise, index)=>(
													<tr key={excercise.id}>
														    <td colSpan={1}>
																{index+1}
															</td>
															<td colSpan={5}>
                                                                
                                                                <h6  style={{color: '#DAC27C'}} className="mb-0">{excercise.name}</h6>
																
															</td>
															<td colSpan={2}>{excercise.type}</td>
                                                            <td colSpan={2}>
                                                            <button 
                                                            onClick={()=>this.updateExerciseActiveStatus(excercise.id, excercise.isActive)}
																 className={excercise.isActive? 'btn-success':'btn-danger'}>
                                                                   {excercise.isActive? 'Active' : 'Deactive'}
                                                            </button>
                                                            </td>
															<td colSpan={2}>
																<button
																	type="button"
                                                            
																	className="btn btn-icon"
																	title="Edit"
																>
                                                            <Link to={`/edit/exercise/${excercise.id}`}>
																<i className="fa fa-edit" />
                                                            </Link>
																</button>
																{/* <button
																    onClick= {()=> this.deleteExcercise(excercise.id)}
																	type="button"
																	className="btn btn-icon js-sweetalert"
																	title="Delete"
																	data-type="confirm"
																>
																	<i className="fa fa-trash-o text-danger" />
																</button> */}
															</td>
														</tr>
																))
															) :
															<h3>No users available right now</h3>
														}
													</tbody>
												</table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="Departments-grid" role="tabpanel">
                                        <div className="row clearfix">
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar1.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">John Smith</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">105</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$3100</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar2.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">Maryam Amiri</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">105</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$3100</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar3.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">Fidel Tonn</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">12</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$1800</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar4.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">Frank Camly</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">105</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$3100</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar2.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">Maryam Amiri</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">105</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$3100</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar1.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">John Smith</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">55</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$12,024</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src="../assets/images/sm/avatar2.jpg" alt="fake_url" />
                                                        <h6 className="mt-3">Maryam Amiri</h6>
                                                        <div className="text-center text-muted mb-3">Web Development</div>
                                                        <button type="button" className="btn btn-icon btn-outline-primary"><i className="fa fa-pencil" /></button>
                                                        <button type="button" className="btn btn-icon btn-outline-danger"><i className="fa fa-trash" /></button>
                                                    </div>
                                                    <div className="card-footer text-center">
                                                        <div className="row clearfix">
                                                            <div className="col-6">
                                                                <h5 className="mb-0">23</h5>
                                                                <div className="text-muted">Employee</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <h5 className="mb-0">$5210</h5>
                                                                <div className="text-muted">Earnings</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* Modal */}
                    <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 style={{color: '#Dac27c'}} className="modal-title" id="exampleModalLabel">Add Exercise</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row clearfix">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                            <input type="text"
                                                value={this.state.excerciseName}
                                                onChange={(event) => {
                                                this.setState({
                                                    excerciseName: event.target.value
                                                })
                                                }}
                                                 className="form-control" placeholder="Excercise Name" name="img" />                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                            <textarea
                                            value={this.state.excerciseDesc}
                                            onChange={(event) => {
                                                this.setState({
                                                    excerciseDesc:event.target.value
                                                })
                                                }} 
                                            className="form-control" placeholder="Excercise Description" 
                                            id="w3review" name="w3review" rows="4" cols="50"></textarea>
   
                                                                                         </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                             Excercise Type   
                                            <select
                                            value={this.state.excerciseType}
                                            onChange={(event) => {
                                                this.setState({
                                                    excerciseType:event.target.value
                                                })
                                                }}
                                             className="form-control show-tick">
															
															<option value='repeats'>Reps</option>
															<option value='duration'>Duration</option>
														</select>    
                                                                                         </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                             Active/ Deactive   
                                            <select
                                            value={this.state.isActive}
                                            onChange={(event) => {
                                                this.setState({
                                                    isActive:event.target.IsActive
                                                })
                                                }}
                                             className="form-control show-tick">
															
															<option value={true}>Active</option>
															<option value={false}>Deactive</option>
														</select>    
                                                                                         </div>
                                        </div>
                                        <div className="col-md-12">
                                            <label>Upload Image</label>
                                            <div className="form-group">
                                                <input type="file"
                                                accept="image/*"
                                            
                                                onChange={(event) => {
                                                this.setState({
                                                    image:event.target.files[0]
                                                })
                                                }}
                                                 className="form-control" placeholder="Upload Image" name="img" />
                                            </div>
                                        </div>
                                        {/* <div className="col-md-12">
                                            <label>Upload Image 2</label>
                                            <div className="form-group">
                                                <input type="file"
                                                accept="image/*"
                                            
                                                onChange={(event) => {
                                                this.setState({
                                                    image1:event.target.files[0]
                                                })
                                                }}
                                                 className="form-control" placeholder="Upload Image" name="img" />
                                                 <button onClick={()=>this.uploadTest()}>test</button>
                                            </div>
                                        </div> */}
                                        <div className="col-md-12">
                                        <label>Upload Video</label>
                                            <div className="form-group">
                                            <input type="file"
                                                accept="video/*"
                                                
                                                onChange={(event) => {
                                                this.setState({
                                                    video:event.target.files[0]
                                                })
                                                }}
                                                 className="form-control" placeholder="Upload Video" name="img" />                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                        <label>Upload Gif</label>
                                            <div className="form-group">
                                                <input type="file"
                                                accept="image/*"
                                                
                                                onChange={(event) => {
                                                this.setState({
                                                    gif:event.target.files[0]
                                                })
                                                }}
                                                 className="form-control" placeholder="Upload Giffs" name="img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button type="button"
                                    onClick={()=>this.uploadData(image, video, gif)}
                                     className="btn btn-primary">Save changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar
})

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(ExcerciseCategory);